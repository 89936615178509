import {BehaviorSubject, combineLatest, Observable, Subject} from "rxjs";
import {PointerCollider} from "./pointer-collider";
import {PointerIntersection} from "./pointer-intersection";
import {Webcad} from "../core";
import PointerInfo = BABYLON.PointerInfo;
import {distinctUntilChanged, map} from "rxjs/operators";
import {Pointer} from "./pointer";
import {workingPlaneUnitSize} from "../models/camera.model";
import {linePlaneIntersection, sqrDistanceVector2, Vector2, Vector3, Vectors2Equal, vectors3Equal} from "../math";
import * as BABYLON from "babylonjs";

export enum PointerEventType {
    DOWN,
    UP,
    CLICK,
    MOVE,
    NONE
}

export enum ButtonType {
    RIGHT,
    LEFT
};


export interface PointerState {
    position: Vector3;
    onScreen: Vector2;
    ctrl: boolean;
    shift: boolean;
    button: ButtonType;
    eventType: PointerEventType;
    intersection: PointerIntersection;
}


export class PointerIntersectionProvider {
    public pointerState: Observable<PointerState>;


    private pointer: Subject<PointerInfo> = new Subject();
    private poinerSub: BABYLON.Observer<BABYLON.PointerInfo>;
    private pointObservable: BABYLON.Observable<BABYLON.PointerInfo>;

    constructor(
        private collidersProvier: Observable<PointerCollider[]>,
        private webcad: Observable<Webcad>,
        private getColliderWithHigherPriority: (p1: PointerIntersection, p2: PointerIntersection, pointer: Pointer) => PointerIntersection
    ) {

        webcad.subscribe((webcad: Webcad) => {
            if (this.poinerSub && this.pointObservable) {
                this.pointObservable.remove(this.poinerSub);
            }
            if (!!webcad) {
                this.pointObservable = webcad.scene.onPointerObservable;
                this.poinerSub = this.pointObservable.add(pi => this.pointer.next(pi));
            }
        });

        this.pointerState = new BehaviorSubject<PointerState>({
            eventType: PointerEventType.NONE,
            ctrl: false,
            shift: false,
            button: ButtonType.LEFT,
            position: {x: 0, y: 0, z: 0},
            onScreen: {x: 0, y: 0},
            intersection: null
        });
        combineLatest(
            this.pointer,
            this.collidersProvier,
            webcad)
            .pipe(
                map(([pointer, colliders, webcad]): PointerState => {
                    const scene: BABYLON.Scene = webcad.scene;
                    const camera: BABYLON.Camera = scene.activeCamera;
                    const untranstlated = scene.unTranslatedPointer;
                    const unitSize = workingPlaneUnitSize(webcad.viewState.camera);
                    const epsilon = 0.005 * unitSize.x;
                    const ray = scene.createPickingRay(untranstlated.x, untranstlated.y, null, camera, false);
                    const onWorkingPlane = linePlaneIntersection(ray.direction, ray.origin, {x: 0, y: 0, z: -1}, {
                        x: 0,
                        y: 0,
                        z: 0
                    });
                    const intersection = this.getIntersection(pointer, colliders, onWorkingPlane, ray, epsilon);
                    const screenX = (untranstlated.x / webcad.viewState.canvasSize.width) * 2 - 1;
                    const screenY = (untranstlated.y / webcad.viewState.canvasSize.height) * -2 + 1;
                    const onScreen = {
                        x: screenX,
                        y: screenY
                    };
                    return {
                        intersection: intersection,
                        position: intersection ? intersection.position : onWorkingPlane,
                        onScreen: onScreen,
                        ctrl: pointer.event.ctrlKey,
                        shift: pointer.event.shiftKey,
                        button: pointer.event.button === 0 ? ButtonType.LEFT : ButtonType.RIGHT,
                        eventType: getEventType(pointer)
                    };
                }),
                distinctUntilChanged((oldState, newState): boolean => {
                    const prevValue = oldState;
                    const positionChanged = !Vectors2Equal(prevValue.position, newState.position);
                    const buttonChanged = prevValue.button !== newState.button;
                    const eventChanged = newState.eventType !== prevValue.eventType;
                    const intersectionChaned = newState.intersection !== prevValue.intersection;
                    const ctrlChanged = newState.ctrl !== prevValue.ctrl;
                    const shiftChanged =newState.shift !== prevValue.shift;
                    return !(positionChanged || buttonChanged || eventChanged || intersectionChaned || ctrlChanged || shiftChanged);
                }),
            )
            .subscribe((ps) => (this.pointerState as BehaviorSubject<PointerState>).next(ps));
    }

    getIntersection(pointerInfo: PointerInfo, colliders: PointerCollider[], onWorkingPlane: Vector3, ray: BABYLON.Ray, epsilon: number): PointerIntersection {

        const pointer: Pointer = {
            ray: ray,
            onWorkingPlane: onWorkingPlane
        };

        let result: PointerIntersection = null;
        for (let i = 0; i < colliders.length; i++) {
            const collider = colliders[i];
            const intersection = collider.getIntersection(pointer, epsilon);
            if (!!intersection) {
                if (!!result) {
                    result = this.getColliderWithHigherPriority(result, intersection, pointer);
                } else {
                    result = intersection;
                }
            }
        }
        return result;
    }
}

function getEventType(event: BABYLON.PointerInfo): PointerEventType {
    switch (event.type) {
        case BABYLON.PointerEventTypes.POINTERTAP:
            return PointerEventType.CLICK;
        case BABYLON.PointerEventTypes.POINTERDOWN:
            return PointerEventType.DOWN;
        case BABYLON.PointerEventTypes.POINTERUP:
            return PointerEventType.UP;
        case BABYLON.PointerEventTypes.POINTERMOVE:
            return PointerEventType.MOVE;
        default:
            return PointerEventType.NONE;
    }
}
