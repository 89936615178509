import {ActionType} from './shape-action.model';
import {deepCopyShapeWithHoles, ShapeWithHoles} from './shape-with-holes';
import {MountingAttachment} from './mounting.model';
import {PerforationAreaModel} from './perforation-area.model';
import {BendingLine} from './bending-line.model';
import {Vector2} from "webcad/math";
import {Segment} from "webcad/models";

export enum PlateMaterialType {
  real,
  shape
}

export interface Plate {
  show: boolean;
  position: number;
  height: number;
  width: number;
  depth: number;
  boundary: Segment[];
  shapeWithHoles?: ShapeWithHoles;
  cutShapeWithHoles?: ShapeWithHoles;
  perforationAreas: PerforationAreaModel[];
  defaultPerforationAreaOffset?: Vector2;
  currentLineShapeId: number;
  actionType: ActionType;
  plateMaterial: PlateMaterialType;
  hexColorString: string;
  mountingHoles: MountingAttachment[];
  autoCenter: boolean;
  bendingLines: BendingLine[];
  bendingLinesDistances: number[];

  //possiblePerforationZones: ShapeWithHoles[];
}

export const initialPlate: Plate = {
  position: -1,
  height: 3,
  width: 5,
  depth: 0.03,
  currentLineShapeId: -1,
  show: true,
  actionType: ActionType.REMOVE,
  plateMaterial: PlateMaterialType.shape,
  autoCenter: true,
  perforationAreas: [],
  hexColorString: '',
  mountingHoles: [],
  shapeWithHoles: {
    holes: [],
    conture: []
  },
  bendingLines: [],
  //possiblePerforationZones: [],
  bendingLinesDistances:[],
  boundary:[]
};



export function deepCopyPerforationAreas(areas: PerforationAreaModel[]): PerforationAreaModel[] {
  return areas.map(area => {
    return {
      shape: deepCopyShapeWithHoles(area.shape),
      rotation: area.rotation,
      offset: {x: area.offset.x, y: area.offset.y}
    };
  });
}
