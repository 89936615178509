import {ModelVisualizer} from '../visualizers';
import {HtmlLayerElement} from './html-layer-element';
import {HtmlLabelModel} from '../models/html-label.model';
import {Webcad} from '../core';
import {Input2DPlacementModel} from '../models';
import {LayerSize} from './layer-size';
import {sqrDistanceVector2, Vector2} from '../math';
import * as BABYLON from 'babylonjs';

export class LabelHtml implements ModelVisualizer<HtmlLabelModel>, HtmlLayerElement {
    element: HTMLLabelElement;
    private height = 20;
    private width = 100;
    private readonly fontSize: number = 11;
    private webcad: Webcad;
    private model: HtmlLabelModel;
    private layerSize: LayerSize;
    private lastValidValue: string;

    dispose(): void {
        if (this.element && this.element.parentNode) {
            this.element.parentNode.removeChild(this.element);
        }
    }

    init(rootNode: BABYLON.Node, model: HtmlLabelModel, webcad: Webcad): Promise<void> {
        this.webcad = webcad;
        this.model = model;
        this.element = document.createElement('label') as HTMLLabelElement;
        this.webcad.getHtmlLayer().add(this);
        this.element.style.pointerEvents = 'none';
        // this.element.style.height = this.height + "px";
        this.element.style.fontSize = this.fontSize + 'px';
        this.width = this.fontSize * this.model.value.length;
        this.element.style.width = 'auto';
        this.element.style.height = 'auto';
        this.element.style.position = 'absolute';
        this.element.style.backgroundColor = '#eeeeee';
        this.element.style.borderStyle = 'solid';
        this.element.style.borderWidth = '1px';
        this.element.style.borderColor = '#000000';
        this.element.style.display = 'none';
        // this.element.style.textAlign = "center";
        this.element.style.zIndex = '2';
        this.updateElementPosition(model && model.placement, model && model.offset, webcad.viewState.canvasSize);
        this.layerSize = webcad.viewState.canvasSize;
        return Promise.resolve();
    }

    updateVisualization(newModel: HtmlLabelModel): void {
        // update value in input
        const newValue = newModel && newModel.value;
        const oldValue = this.model && this.model.value;
        if (newValue !== oldValue) {
            this.element.innerText = newValue && newModel.value;
            this.lastValidValue = this.element.innerText;
            // this.width = this.fontSize * newModel.value.length;
            // this.element.style.width = this.width + "px";
        }

        // update placement
        const newSize: LayerSize = this.webcad.viewState.canvasSize;
        const newPlcment = newModel.placement;
        const oldPlcment = this.model && this.model.placement;
        if(newPlcment.position && oldPlcment.position) {
            if (sqrDistanceVector2(newPlcment.position, oldPlcment.position) > 0.000001 || this.layerSize !== newSize || newModel.offset !== this.model.offset) {
                this.updateElementPosition(newPlcment, newModel.offset, newSize);
            }
        }
        this.element.style.display = newModel.visible ? 'inline' : 'none';
        this.model = newModel;
        this.layerSize = newSize;
    }


    private updateElementPosition(placement: Input2DPlacementModel, offset: Vector2, layerSize: LayerSize): void {
        if (!!placement) {
            const onScreenX = placement.position.x > -1 || placement.position.x < 1;
            const onScreenY = placement.position.y > -1 || placement.position.y < 1;
            const onScreen = onScreenX && onScreenY;
            this.element.style.display = onScreen ? 'inline' : 'none';
            this.element.style.left = (placement.position.x / 2 + 0.5) * layerSize.width + offset.x + 'px';
            this.element.style.top = (-placement.position.y / 2 + 0.5) * layerSize.height - this.height / 2 - offset.y + 'px';
            this.element.style.transform = 'rotate(' + Math.round(placement.angle * 180 / Math.PI) + 'deg)';
        } else {
            this.element.style.display = 'none';
        }
    }

}
