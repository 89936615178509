import * as BABYLON from 'babylonjs'
import {AssetsProvider} from "./assets.provider";

export class BabylonAssetsProvider implements AssetsProvider {

    private loadPromise: Promise<BABYLON.Scene>;
    private texturesCache: Map<string, BABYLON.Texture>;

    constructor(private engine: BABYLON.Engine,
                private rootUrl: string,
                private assetsFile: string,
                private textureRootPath: string) {
        engine.enableOfflineSupport = false;
        this.texturesCache = new Map<string, BABYLON.Texture>();
    }

    /*
    init(engine:BABYLON.Engine, rootUrl:string, assetsFile:string){
      this.engine = engine;
    }*/

    private getLoadPromise(): Promise<BABYLON.Scene> {
        if (!this.loadPromise) {
            this.loadPromise = new Promise((resolve, reject) => {
                BABYLON.SceneLoader.Load(this.rootUrl/*'/assets/'*/, this.assetsFile/* 'wall.babylon'*/, this.engine, (scene) => {
                    resolve(scene)
                }, null, (scene, message, exception) => {
                    reject(message);
                });
            })
        }
        return this.loadPromise;
    }

    //lazy
    getMesh(name: string): Promise<BABYLON.Mesh> {
        return new Promise<BABYLON.Mesh>((resolve, reject) => {
            this.getLoadPromise().then(
                scene => {
                    const mesh = scene.getMeshByName(name)as BABYLON.Mesh;
                    if (mesh) {
                        resolve(scene.getMeshByName(name)as BABYLON.Mesh);
                    } else {
                        reject(new Error("Mesh not found in assets file: " + name))
                    }
                },
                reject
            )
        });
    }

    getTexture(name: string, scene: BABYLON.Scene): BABYLON.Texture {
        const texture = this.texturesCache.get(name);
        if (texture) {
            return texture;
        }
        else {
            if (this.textureRootPath) {
                this.texturesCache.set(name, new BABYLON.Texture(this.textureRootPath + name, scene));
                return this.texturesCache.get(name);
            }
            else {
                return null;
            }
        }
    }
}
