import { isAabb2Empty, SegmentType, subVectors2 } from 'webcad';
import {
  Dataset,
  getFirstMaterailCode,
} from '../../model/dataset/dataset.model';
import { stringToFormat } from '../../model/dataset/perforation-format.model';
import { mapToEffectWithInput } from '../../model/effect.model';
import { SelectedFading } from '../../model/fading.model';
import { MevacoPage } from '../../model/mevaco-page.model';
import { getPerforationAreasAabb } from '../../model/perforation-area.model';
import {
  perforationAabb,
  perforationHolesPositionsAabb,
} from '../../model/perforation.model';
import { Element } from '../../model/product-configuration/element.model';
import { MinMaxRaender } from '../../model/product-configuration/min-max-raender.model';
import {
  createDefaultStamp,
  PerforationCustomStump,
} from '../../model/product-configuration/perforation-custom-geom';
import { PerforationType } from '../../model/product-configuration/perforation-type.model';
import {
  createFormatString,
  formatToString,
} from '../../model/product-configuration/perforation.model';
import {
  initialProductConfiguration,
  ProductConfiguration,
} from '../../model/product-configuration/product-configuration.model';
import {
  CLEAR_ALL_ELEMENTS,
  DATASET_LOMOE_EWZ_LOADED_SUCCESSFULLY,
  ENABLE_EFFECT,
  ENABLE_FADING,
  ENABLE_PERFACTION, EXPANDED_METAL_SIZE_CHANGE, EXPANDED_METAL_TYPE_CHANGE, EXPANDED_METAL_TYPE_OF_CUT_CHANGE,
  GEOMATRIX_LOADED_SUCCESSFULLY,
  PERFORATION_FORMAT_CHANGE,
  PERFORATION_GEOMETRY_CHANGE,
  PERFORATION_POSITION_CHANGE,
  PERFORATION_SHAPE_CHANGE,
  PERFORATION_SIZE_CHANGE,
  PRODUCT_CONFIGURATION_LOADED_SUCCESSFULLY,
  PRODUCT_CONFIGURATION_REMOVE_ALL_ELEMENTS,
  PRODUCT_CONFIGURATION_REMOVE_ELEMENT,
  PRODUCT_CONFIGURATION_SET_COLOR,
  PRODUCT_CONFIGURATION_SET_COLOR_GROUP,
  PRODUCT_CONFIGURATION_SET_COLOR_SYSTEM,
  PRODUCT_CONFIGURATION_SET_LEVEL_OF_GLOSS,
  PRODUCT_CONFIGURATION_SET_MATERIAL_THICKNESS,
  PRODUCT_CONFIGURATION_SET_P1,
  PRODUCT_CONFIGURATION_SET_P2,
  PRODUCT_CONFIGURATION_SET_POLISHING,
  PRODUCT_CONFIGURATION_SET_UNDERCOATING,
  RESET_CUSTOM_PATTERN, RESET_CUSTOM_SUCCEEDED,
  SET_CUSTOM_PATTERN, SET_DIVING_DEPTH, SET_FEEDRATE,
  SET_MATERIAL_SUCCESS,
  SET_NEED_PREVIEW_UPDATE,
  SET_PREVIEW_IMAGE_ID, SET_STEGCLUSTER,
  SINGLE_TOOLS_AND_MATERIAL_LOADED_SUCCESSFULLY,
  WALLPAPER_AND_MATERIAL_LOADED_SUCCESSFULLY,
} from '../actions';
import {
  AUTO_FIR_CUSTOM_PATTERN,
  SET_CUSTOM_OFFSET_X,
  SET_CUSTOM_OFFSET_Y,
  SET_CUSTOM_STAMP_END_X,
  SET_CUSTOM_STAMP_END_Y,
  SET_CUSTOM_STAMP_FORM,
  SET_CUSTOM_STAMP_FORM_TYPE,
  SET_CUSTOM_STAMP_LENGTH,
  SET_CUSTOM_STAMP_OFFSET_X,
  SET_CUSTOM_STAMP_OFFSET_Y,
  SET_CUSTOM_STAMP_ROTATION,
  SET_CUSTOM_STAMP_START_X,
  SET_CUSTOM_STAMP_START_Y,
  SET_CUSTOM_STAMP_WIDTH,
  SET_SMART_MARGIN,
} from '../actions/custom-geom.actions';
import {
  DATASET_LOMOE_EWZ_MATERIAL_GEOMATRIX_LOADED_SUCCESSFULLY,
  DATASET_MATERIA_LOADED_SUCCESSFULLY,
  DATASET_WALLPAPER_LOADED_SUCCESSFULLY,
  MATERIAL_FOR_WALLPAPER_LOADED_SUCCESSFULLY,
} from '../actions/dataset.actions';
import {
  ADD_OFFSET_TO_PLATE_PEFRACTION,
  COPY_POSITION_SUCCESS,
  DELETE_PERFACTION_IMAGE_SUCCESSFULLY,
  DUPLICATE_CONFIGURATION_SUCCESS,
  PERFORATION_TYPE_CHANGE,
  PRODUCT_CONFIGURATION_SET_FOIL,
  PRODUCT_CONFIGURATION_SET_MATERIAL,
  PRODUCT_CONFIGURATION_SET_PERFACTION_FORM,
  PRODUCT_CONFIGURATION_SET_PERFACTION_FORMAT,
  PRODUCT_CONFIGURATION_SET_PERFACTION_LABEL,
  PRODUCT_CONFIGURATION_SET_PERFACTION_SIZES,
  PRODUCT_CONFIGURATION_SET_QUALITY,
  PRODUCT_CONFIGURATION_SET_SURFACE_TYPE,
  PRODUCT_TYPE_CHANGE,
  ProductConfigurationActions,
  SET_EFFECTS,
  SET_FADINGS,
  SET_MIN_MAX_REANDER,
  SET_NOTE,
  SET_PERFACTION_CONFIG,
  SET_PERFACTION_IMAGE_CONFIG,
  SET_PERFACTION_IMAGE_ID,
  SET_QUANTITY,
  SetMinMaxReander,
  WALLPAPER_ALLOW_HOLES_UPDATE,
  WALLPAPER_CHANGE,
  WALLPAPER_ZOOM_UPDATE,
} from '../actions/product-configuration.actions';
import { findBiggestHole } from './index';

export function productConfigurationReducer(
  state: ProductConfiguration = initialProductConfiguration,
  dataset: Dataset,
  mevacoPage: MevacoPage,
  action: ProductConfigurationActions
): ProductConfiguration {
  switch (action.type) {
    case PERFORATION_TYPE_CHANGE:
      return {
        ...state,
        activePerforation: action.payload,
      };
    case PERFORATION_GEOMETRY_CHANGE:
      return {
        ...state,
        perforation: {
          ...state.perforation,
          geometryType: action.payload as string,
          perforationType: '',
          size: '',
          spacing: '',
          format: '',
          format1: '',
          format2: '',
        },
      };
    case EXPANDED_METAL_TYPE_CHANGE:
      return {
        ...state,
        extendedMetal: {
          ...state.extendedMetal,
          type: action.payload as string,
          size: '',
          stegcluster: '',
          feedrate: '',
          openMesh: '',
          typeOfCut: state.extendedMetal.typeOfCut || 'D'
        }
      };

    case EXPANDED_METAL_SIZE_CHANGE:
      return {
        ...state,
        extendedMetal: {
          ...state.extendedMetal,
          size: action.payload as string,
          stegcluster: '',
          feedrate: '',
          openMesh: '',
          typeOfCut: state.extendedMetal.typeOfCut || 'D'
        }
      };

    case EXPANDED_METAL_TYPE_OF_CUT_CHANGE:
      return {
        ...state,
        extendedMetal: {
          ...state.extendedMetal,
          typeOfCut: action.payload
        }
      };

    case SET_STEGCLUSTER:
      return {
        ...state,
        extendedMetal: {
          ...state.extendedMetal,
          stegcluster: action.payload,
          feedrate: '',
          openMesh: '',
          typeOfCut: state.extendedMetal.typeOfCut || 'D'
        }
      };
    case SET_FEEDRATE:
      return {
        ...state,
        extendedMetal: {
          ...state.extendedMetal,
          feedrate: action.payload,
          openMesh: '',
          typeOfCut: state.extendedMetal.typeOfCut || 'D'
        }
      };

    case PRODUCT_TYPE_CHANGE:
      return{
        ...state,
        productType: action.payload as number
      };
    case PERFORATION_SHAPE_CHANGE:
      return {
        ...state,
        perforation: {
          ...state.perforation,
          perforationType: action.payload as string,
          size: '',
          format: '',
          spacing: '',
          format1: '',
          format2: '',
          position: '',
        },
      };
    case PERFORATION_SIZE_CHANGE:
      return {
        ...state,
        perforation: {
          ...state.perforation,
          size: action.payload as string,
          spacing: '',
          format: '',
          format1: '',
          format2: '',
        },
      };

    case SET_CUSTOM_PATTERN:
      return {
        ...state,
        perforation: {
          ...state.perforation,
          custom: JSON.parse(action.payload.template),
        },
      };
    case RESET_CUSTOM_SUCCEEDED:
      return {
        ...state,
        perforation: {
          ...state.perforation,
          custom: action.payload
        }
      };
      /*
    case RESET_CUSTOM_PATTERN:
      return {
        ...state,
        perforation: {
          ...state.perforation,
          custom: createPerforationCustomData(state, dataset),
        },
      };
      */
    case WALLPAPER_ALLOW_HOLES_UPDATE:
      return {
        ...state,
        wallpaper: {
          ...state.wallpaper,
          halfHolesEnabled: action.payload,
        },
      };
    case WALLPAPER_ZOOM_UPDATE:
      return {
        ...state,
        wallpaper: {
          ...state.wallpaper,
          zoomEnabled: action.payload as number,
        },
      };
    case WALLPAPER_CHANGE:
      return {
        ...state,
        wallpaper: {
          ...state.wallpaper,
          name: action.payload as string,
          halfHolesEnabled: 0,
          zoomEnabled: 1,
        },
      };
    case PERFORATION_FORMAT_CHANGE:
      const format = stringToFormat(action.payload);
      const payload = action.payload;
      return {
        ...state,
        perforation: {
          ...state.perforation,
          spacing: action.payload === 'Individual' ? 'Individual' : 'Standard',
          format:
            action.payload === 'Individual' ? '' : (action.payload as string),
          format1:
            action.payload === 'Individual'
              ? ''
              : format.p1 !== 0
              ? format.p1.toString()
              : '',
          format2:
            action.payload === 'Individual'
              ? ''
              : format.p2 !== 0
              ? format.p2.toString()
              : '',
        },
      };

    case PERFORATION_POSITION_CHANGE:
      return {
        ...state,
        perforation: {
          ...state.perforation,
          position: action.payload,
        },
      };

    case PRODUCT_CONFIGURATION_LOADED_SUCCESSFULLY:
    case DUPLICATE_CONFIGURATION_SUCCESS:
      const loadedConfiguration: ProductConfiguration = {...action.payload};
      const props = Object.keys(initialProductConfiguration);
      for (let i = 0; i < props.length; i++) {
        const prop = props[i];
        if (
          loadedConfiguration[prop] === null ||
          loadedConfiguration[prop] === undefined
        ) {
          loadedConfiguration[prop] = initialProductConfiguration[prop];
        }
      }
      // all configurations from designer are in individual format
      loadedConfiguration.format = {
        ...loadedConfiguration.format,
        type: 'individual',
        name: 'individual'
      };
      if ((loadedConfiguration.activePerforation as null) == 0) {
        loadedConfiguration.activePerforation = PerforationType.CLASSIC;
      }
      if ((loadedConfiguration.activePerforation as null) == 1) {
        loadedConfiguration.activePerforation = PerforationType.WALLPAPER;
      }
      // loadedConfiguration.productType = state.productType;
      return loadedConfiguration;

    case PRODUCT_CONFIGURATION_REMOVE_ELEMENT: {
      const elements: Element[] = [];
      for (let i = 1; i < state.elements.length + 1; i++) {
        const element: Element = state.elements[i - 1];
        if (i < action.payload) {
          elements.push(element);
        } else if (i > action.payload) {
          elements.push({ ...element, position: element.position - 1 });
        }
      }
      return {
        ...state,
        elements: elements,
      };
    }

    case PRODUCT_CONFIGURATION_REMOVE_ALL_ELEMENTS: {
      return {
        ...state,
        elements: [],
      };
    }

    case PRODUCT_CONFIGURATION_SET_P1:
      return {
        ...state,
        perforation: {
          ...state.perforation,
          format: createFormatString(
            state.perforation,
            action.payload,
            state.perforation.format2
          ),
          format1: action.payload,
        },
      };

    case PRODUCT_CONFIGURATION_SET_P2:
      return {
        ...state,
        perforation: {
          ...state.perforation,
          format: createFormatString(
            state.perforation,
            state.perforation.format1,
            action.payload
          ),
          format2: action.payload,
        },
      };
    case PRODUCT_CONFIGURATION_SET_MATERIAL:
      return {
        ...state,
        material: {
          materialType: action.payload,
          quality: '',
          thickness: '',
          foil: 'None',
        },
      };
    case PRODUCT_CONFIGURATION_SET_MATERIAL_THICKNESS:
      return {
        ...state,
        material: {
          ...state.material,
          thickness: action.payload,
          quality: getFirstMaterailCode(
            dataset,
            state.material.materialType,
            action.payload
          ),
          foil: 'None',
        },
      };

    case PRODUCT_CONFIGURATION_SET_QUALITY:
      return {
        ...state,
        material: {
          ...state.material,
          quality: action.payload,
          foil: 'None',
        },
      };

    case PRODUCT_CONFIGURATION_SET_FOIL:
      return {
        ...state,
        material: {
          ...state.material,
          foil: action.payload,
        },
      };
    case PRODUCT_CONFIGURATION_SET_SURFACE_TYPE:
      return {
        ...state,
        surface: {
          surfaceType: action.payload,
          colorSystem: '',
          colorGroup: '',
          color: '',
          undercoating: '',
          levelOfGloss: '',
          polishedSides: '',
        },
      };
    case PRODUCT_CONFIGURATION_SET_POLISHING:
      return {
        ...state,
        surface: {
          ...state.surface,
          polishedSides: action.payload as string,
        },
      };
    case PRODUCT_CONFIGURATION_SET_COLOR_SYSTEM:
      return {
        ...state,
        surface: {
          ...state.surface,
          colorSystem: action.payload as string,
          colorGroup: '',
          color: '',
          undercoating: '',
          levelOfGloss: '',
        },
      };
    case PRODUCT_CONFIGURATION_SET_COLOR_GROUP:
      return {
        ...state,
        surface: {
          ...state.surface,
          colorGroup: action.payload as string,
          color: '',
          undercoating: '',
          levelOfGloss: '',
        },
      };
    case PRODUCT_CONFIGURATION_SET_COLOR:
      return {
        ...state,
        surface: {
          ...state.surface,
          color: action.payload as string,
          undercoating: '',
          levelOfGloss: '',
        },
      };
    case PRODUCT_CONFIGURATION_SET_UNDERCOATING:
      return {
        ...state,
        surface: {
          ...state.surface,
          undercoating: action.payload as string,
          levelOfGloss: '',
        },
      };
    case PRODUCT_CONFIGURATION_SET_LEVEL_OF_GLOSS:
      return {
        ...state,
        surface: {
          ...state.surface,
          levelOfGloss: action.payload as string,
        },
      };
    case SET_NEED_PREVIEW_UPDATE:
      const newElement = { ...state.elements[action.payload.id - 1] };
      const newElements = [...state.elements];
      newElements[action.payload.id - 1] = newElement;
      return {
        ...state,
        elements: newElements,
      };
    case SET_PREVIEW_IMAGE_ID: {
      const newEle = { ...state.elements[action.payload.elementId - 1] };
      const newEles = [...state.elements];
      newEle.previewImageId = action.payload.imageGuid;
      newEles[action.payload.elementId - 1] = newEle;
      return {
        ...state,
        elements: newEles,
      };
    }
    case SET_QUANTITY: {
      const newEle = { ...state.elements[action.payload.elementId - 1] };
      const newEles = [...state.elements];
      newEle.quantity = action.payload.quantity;
      newEles[action.payload.elementId - 1] = newEle;
      return {
        ...state,
        elements: newEles,
      };
    }
    case SET_NOTE: {
      const newEle = { ...state.elements[action.payload.elementId - 1] };
      const newEles = [...state.elements];
      newEle.note = action.payload.note;
      newEle.label = action.payload.note;
      newEles[action.payload.elementId - 1] = newEle;
      return {
        ...state,
        elements: newEles,
      };
    }
    case CLEAR_ALL_ELEMENTS:
      return {
        ...state,
        elements: [],
      };
    case DATASET_WALLPAPER_LOADED_SUCCESSFULLY:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          wallpaper: action.payload || { id: 0 },
        },
      };
    case WALLPAPER_AND_MATERIAL_LOADED_SUCCESSFULLY:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          wallpaper: action.payload.wallpaper,
          material: action.payload.material || state.configuration.material,
        },
      };

    case SINGLE_TOOLS_AND_MATERIAL_LOADED_SUCCESSFULLY:
      const singleTools = action.payload.singleTools;
      return {
        ...state,
        configuration: {
          ...state.configuration,
          singleTools: singleTools,
          material: action.payload.material || state.configuration.material,
          allSingleToolsFound:
            singleTools.length > 0 &&
            !singleTools.some((st) => !st || st.id === 0),
        },
      };
    case DATASET_MATERIA_LOADED_SUCCESSFULLY:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          material: action.payload || { id: 0 },
        },
      };
    case DATASET_LOMOE_EWZ_LOADED_SUCCESSFULLY:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          ...action.payload,
        },
        perforation: {
          ...state.perforation,
          position:
            (action.payload.geomatrix.codeLochanfang &&
              action.payload.geomatrix.codeLochanfang.toString()) ||
            '',
        },
      };
    case MATERIAL_FOR_WALLPAPER_LOADED_SUCCESSFULLY:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          lomoe: { id: 0 },
          material: action.payload || { id: 0 },
          ewz: { id: 0 },
          geomatrix: { id: 0 },
        },
      };
    case GEOMATRIX_LOADED_SUCCESSFULLY:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          geomatrix: action.payload || { id: 0 },
        },
      };
    case DATASET_LOMOE_EWZ_MATERIAL_GEOMATRIX_LOADED_SUCCESSFULLY:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          lomoe: action.payload.lomoe,
          ewz: action.payload.ewz,
          material: action.payload.material || state.configuration.material,
        },
      };
    case SET_MATERIAL_SUCCESS:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          lomoe: action.payload.lomoe || { id: 0 },
          material: action.payload.material || { id: 0 },
        },
      };

    // case SET_PERFORATION_ROTATION:
    //   return {
    //     ...state,
    //     perforation:{
    //       ...state.perforation,
    //       rotation: action.payload
    //     }
    //   };
    case COPY_POSITION_SUCCESS: {
      if (action.payload) {
        return {
          ...state,
          elements: action.payload,
        };
      } else {
        return state;
      }
    }
    case SET_MIN_MAX_REANDER: {
      if (action.payload) {
        const newMinMax: MinMaxRaender = (<SetMinMaxReander>action).payload;
        if (newMinMax.minE1E2 != undefined && newMinMax.minF1F2 != undefined) {
          newMinMax.max = Math.max(newMinMax.minE1E2, newMinMax.minF1F2);
        }
        return {
          ...state,
          configuration: {
            ...state.configuration,
            minMaxRaender: newMinMax,
          },
        };
      }
      break;
    }
    case SET_CUSTOM_OFFSET_X: {
      return {
        ...state,
        perforation: {
          ...state.perforation,
          custom: {
            ...state.perforation.custom,
            offsetX: action.payload.offsetX,
          },
        },
      };
    }
    case SET_CUSTOM_OFFSET_Y: {
      return {
        ...state,
        perforation: {
          ...state.perforation,
          custom: {
            ...state.perforation.custom,
            offsetY: action.payload.offsetY,
          },
        },
      };
    }

    case SET_CUSTOM_STAMP_FORM_TYPE: {
      const { changedState, newStamp } = changeCustomStampProperty(
        state,
        mevacoPage.selectedCustomStamp
      );
      newStamp.formType = action.payload.type;
      newStamp.form = '';
      newStamp.w = 0;
      newStamp.l = 0;
      return changedState;
    }
    case SET_CUSTOM_STAMP_FORM: {
      const { changedState, newStamp } = changeCustomStampProperty(
        state,
        mevacoPage.selectedCustomStamp
      );
      newStamp.form = action.payload.form;
      const defaultSize = dataset.singleTools.find(
        (st) => st.form === action.payload.form && st.defaultSize === 1
      );
      newStamp.w = defaultSize.width;
      newStamp.l = defaultSize.lenght;
      return changedState;
    }
    case SET_CUSTOM_STAMP_WIDTH: {
      const { changedState, newStamp } = changeCustomStampProperty(
        state,
        mevacoPage.selectedCustomStamp
      );
      newStamp.w = action.payload.width;
      newStamp.l = 0;
      return changedState;
    }
    case SET_CUSTOM_STAMP_LENGTH: {
      const { changedState, newStamp } = changeCustomStampProperty(
        state,
        mevacoPage.selectedCustomStamp
      );
      newStamp.l = action.payload.length;
      return changedState;
    }
    case SET_CUSTOM_STAMP_ROTATION: {
      const { changedState, newStamp } = changeCustomStampProperty(
        state,
        mevacoPage.selectedCustomStamp
      );
      newStamp.rotation = action.payload.rotation;
      return changedState;
    }
    case SET_CUSTOM_STAMP_OFFSET_X: {
      const { changedState, newStamp } = changeCustomStampProperty(
        state,
        mevacoPage.selectedCustomStamp
      );
      newStamp.offsetDivX = action.payload.offsetX;
      return changedState;
    }
    case SET_CUSTOM_STAMP_OFFSET_Y: {
      const { changedState, newStamp } = changeCustomStampProperty(
        state,
        mevacoPage.selectedCustomStamp
      );
      newStamp.offsetDivY = action.payload.offsetY;
      return changedState;
    }
    case SET_CUSTOM_STAMP_START_X: {
      const { changedState, newStamp } = changeCustomStampProperty(
        state,
        mevacoPage.selectedCustomStamp
      );
      newStamp.startX = action.payload.startX;
      return changedState;
    }
    case SET_CUSTOM_STAMP_START_Y: {
      const { changedState, newStamp } = changeCustomStampProperty(
        state,
        mevacoPage.selectedCustomStamp
      );
      newStamp.startY = action.payload.startY;
      return changedState;
    }
    case SET_CUSTOM_STAMP_END_X: {
      const { changedState, newStamp } = changeCustomStampProperty(
        state,
        mevacoPage.selectedCustomStamp
      );
      newStamp.endX = action.payload.endX;
      return changedState;
    }
    case SET_CUSTOM_STAMP_END_Y: {
      const { changedState, newStamp } = changeCustomStampProperty(
        state,
        mevacoPage.selectedCustomStamp
      );
      newStamp.endY = action.payload.endY;
      return changedState;
    }
    case AUTO_FIR_CUSTOM_PATTERN:
      {
        const perforationAreaAabb = getPerforationAreasAabb(
          mevacoPage.drawing.plate.perforationAreas
        );
        if (!isAabb2Empty(perforationAreaAabb)) {
          const perforationRealArea = perforationAabb(
            mevacoPage.lastPerforationResponse
          );
          const positionsAabb = perforationHolesPositionsAabb(
            mevacoPage.lastPerforationResponse
          );
          const custom = state.perforation.custom;
          if (action.payload === 'X') {
            const perforationAreaAabbSize =
              (perforationAreaAabb.max.x - perforationAreaAabb.min.x) * 1000;
            const perforationRealAreaSize =
              (perforationRealArea.max.x - perforationRealArea.min.x) * 1000;
            const positionsAabbSize =
              (positionsAabb.max.x - positionsAabb.min.x) * 1000;
            const holesRatio = positionsAabbSize / custom.offsetX;
            const offsetX =
              Math.floor(
                1000 *
                  (custom.offsetX +
                    (perforationAreaAabbSize - perforationRealAreaSize) /
                      holesRatio)
              ) / 1000;

            return {
              ...state,
              perforation: {
                ...state.perforation,
                custom: {
                  ...state.perforation.custom,
                  offsetX,
                },
              },
            };
          }
          if (action.payload === 'Y') {
            const perforationAreaAabbSize =
              (perforationAreaAabb.max.y - perforationAreaAabb.min.y) * 1000;
            const perforationRealAreaSize =
              (perforationRealArea.max.y - perforationRealArea.min.y) * 1000;
            const positionsAabbSize =
              (positionsAabb.max.y - positionsAabb.min.y) * 1000;
            const holesRatio = positionsAabbSize / custom.offsetY;
            const offsetY =
              Math.floor(
                1000 *
                  (custom.offsetY +
                    (perforationAreaAabbSize - perforationRealAreaSize) /
                      holesRatio)
              ) / 1000;

            return {
              ...state,
              perforation: {
                ...state.perforation,
                custom: {
                  ...state.perforation.custom,
                  offsetY,
                },
              },
            };
          }
        }
      }
      break;
    case SET_EFFECTS: {
      const deepCopy = JSON.parse(JSON.stringify(action.payload));
      const resEffectState = {
        ...state,
        effect: deepCopy,
      };
      return {
        ...resEffectState,
      };
    }
    case SET_FADINGS: {
      if (!!action.payload) {
        const deepCopy = JSON.parse(JSON.stringify(action.payload));
        const resFadingState = {
          ...state,
          fading: {
            ...state.fading,
            ...deepCopy,
          },
        };
        return {
          ...resFadingState,
        };
      } else {
        return {
          ...state,
          fading: {
            enabled: state.fading.enabled,
          } as SelectedFading,
        };
      }
    }
    case SET_PERFACTION_CONFIG: {
      const deepCopy = JSON.parse(JSON.stringify(action.payload));
      const resSatate = {
        ...state,
        perfaction: {
          ...state.perfaction,
          config: {
            ...state.perfaction.config,
            ...deepCopy,
          },
        },
      };
      return {
        ...resSatate,
      };
    }
    case SET_PERFACTION_IMAGE_CONFIG: {
      const deepCopy = JSON.parse(JSON.stringify(action.payload));
      const resSatate = {
        ...state,
        perfaction: {
          ...state.perfaction,
          imageConfig: {
            ...deepCopy,
          },
        },
      };
      return {
        ...resSatate,
      };
    }
    case SET_PERFACTION_IMAGE_ID: {
      const deepCopy = JSON.parse(JSON.stringify(action.payload));
      const resSatate = {
        ...state,
        perfaction: {
          ...state.perfaction,
          imageId: deepCopy.id,
        },
      };
      return {
        ...resSatate,
      };
    }
    case DELETE_PERFACTION_IMAGE_SUCCESSFULLY:
      const resState = {
        ...state,
        perfaction: {
          ...state.perfaction,
          imageId: null,
        },
      };
      return {
        ...resState,
      };
    case SET_SMART_MARGIN:
      {
        const proportion = findSmartMarginProportions(mevacoPage);
        if (proportion) {
          return {
            ...state,
            perforation: {
              ...state.perforation,
              custom: {
                ...state.perforation.custom,
                offsetX: proportion * state.perforation.custom.offsetY,
              },
            },
          };
        }
      }
      break;
    case ADD_OFFSET_TO_PLATE_PEFRACTION:
      const elements: Element[] = [...state.elements];
      const oldEl = elements[action.payload.plateIndex];
      elements[action.payload.plateIndex] = {
        ...oldEl,
        perfactionOffset: {
          x:
            (oldEl.perfactionOffset ? oldEl.perfactionOffset.x : 0) +
            action.payload.offset.x,
          y:
            (oldEl.perfactionOffset ? oldEl.perfactionOffset.y : 0) +
            action.payload.offset.y,
        },
      };
      return {
        ...state,
        elements,
      };
    case PRODUCT_CONFIGURATION_SET_PERFACTION_FORM:
      return {
        ...state,
        multiSizePattern: {
          ...state.multiSizePattern,
          form: action.payload,
          label: '',
          holeSizes: [],
          format: '',
        },
      };
    case PRODUCT_CONFIGURATION_SET_PERFACTION_LABEL:
      const perfactionPatterns = mevacoPage.dataset.perfactionPatterns.find(
        (pp) =>
          pp.form === state.multiSizePattern.form && pp.label === action.payload
      );
      const defaultHoleSizes = perfactionPatterns.defaultSizes
        .split(',')
        .map((x) => perfactionPatterns.form + ' ' + x);
      return {
        ...state,
        multiSizePattern: {
          ...state.multiSizePattern,
          label: action.payload,
          holeSizes: defaultHoleSizes,
          format: getDefaultMultiSizeFormat(
            defaultHoleSizes,
            action.payload,
            state.multiSizePattern.format,
            dataset
          ),
        },
      };
    case PRODUCT_CONFIGURATION_SET_PERFACTION_SIZES: {
      const holeSizes = action.payload;
      return {
        ...state,
        multiSizePattern: {
          ...state.multiSizePattern,
          holeSizes,
          format: getDefaultMultiSizeFormat(
            holeSizes,
            state.multiSizePattern.label,
            state.multiSizePattern.format,
            dataset
          ),
        },
      };
    }
    case PRODUCT_CONFIGURATION_SET_PERFACTION_FORMAT:
      return {
        ...state,
        multiSizePattern: {
          ...state.multiSizePattern,
          format: action.payload,
        },
      };
    case ENABLE_PERFACTION:
      return {
        ...state,
        perfaction: {
          ...state.perfaction,
          enabled: action.payload,
        },
      };
    case ENABLE_EFFECT:
      if (!!state.effect) {
        return {
          ...state,
          effect: {
            ...state.effect,
            enabled: action.payload,
          },
        };
      } else if (action.payload) {
        return {
          ...state,
          effect: mapToEffectWithInput(dataset.effects[0], true),
        };
      }
      break;
    case ENABLE_FADING:
      return {
        ...state,
        fading: {
          ...state.fading,
          enabled: action.payload,
        },
      };
  }
  return state;
}

export function changeCustomStampProperty(
  state: ProductConfiguration,
  index: number
): { changedState: ProductConfiguration; newStamp: PerforationCustomStump } {
  const changedState: ProductConfiguration = {
    ...state,
    perforation: {
      ...state.perforation,
      custom: {
        ...state.perforation.custom,
        stumps: [...state.perforation.custom.stumps],
      },
    },
  };

  const newStamp: PerforationCustomStump = changedState.perforation.custom
    .stumps[index]
    ? { ...changedState.perforation.custom.stumps[index] }
    : createDefaultStamp();

  changedState.perforation.custom.stumps[index] = newStamp;
  return {
    changedState,
    newStamp,
  };
}

function findSmartMarginProportions(mevacoPage: MevacoPage): number {
  const shape = mevacoPage.drawing.plate.shapeWithHoles.conture;
  let proportion = 0;
  let maxY = 0;
  for (let i = 0; i < shape.length; i++) {
    const segment = shape[i];
    if (segment.type === SegmentType.line) {
      const dir = subVectors2(segment.end, segment.begin);
      dir.x = Math.abs(dir.x);
      dir.y = Math.abs(dir.y);
      if (dir.y !== 0) {
        const p = dir.x / dir.y;
        if (p <= 5 && p >= 0.2 && dir.y > maxY) {
          proportion = p;
          maxY = dir.y;
        }
      }
    }
  }
  return proportion;
}

function GetEffectFadingPerfactionSelectedField(
  configuration: ProductConfiguration
): boolean {
  return (
    configuration.effect != null ||
    configuration.fading != null ||
    /*configuration.perfaction.imageConfig != null && configuration.perfaction.imageConfig != null && */ configuration
      .perfaction.imageId != null
  );
}

function getDefaultMultiSizeFormat(
  holeSizes: string[],
  label: string,
  format: string,
  dataset: Dataset
): string {
  const biggestHole = findBiggestHole(holeSizes);
  if (biggestHole) {
    const perfactionPattern = dataset.perfactionPatterns.find(
      (pp) => pp.label === label
    );
    const perfType = dataset.perforationTypes.find(
      (pt) => pt.name === perfactionPattern.form
    );
    const perforation = perfType.perforations.find(
      (p) => p.name === perfactionPattern.pattern
    );
    const perforationSize = perforation.sizes.find(
      (ps) => ps.width === biggestHole.width && ps.length === biggestHole.length
    );

    const formats = perforationSize.formats.map((f) => formatToString(f));
    if (formats.indexOf(format) !== -1) {
      return format;
    } else {
      return formats[0] || '';
    }
  } else {
    return '';
  }
}
