import Matrix = BABYLON.Matrix;
import {getOrigin, getZAxis, Matrix4, projectPos} from "../math/matrix4";
import {Vector2, lengthVector3, multiplyVector3byScalar, Vector3} from "../math";

export interface CameraModel {
    projection:Matrix4;
    view:Matrix4;
}

export function workingPlaneUnitSize(cam:CameraModel):Vector2{
    const camDir = getZAxis(cam.view);
    const pos = getOrigin(cam.view);
    const t = camDir.z/pos.z;
    const toPlane = multiplyVector3byScalar(camDir, t);
    const z = lengthVector3( toPlane );
    const vec3:Vector3 = projectPos(cam.projection, {x:1,y:1,z:z});
    return vec3;
}