import {AssetsProvider} from '../providers';
import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import {RotateCamera} from '../components';

export interface CameraInfo {
    alpha: number;
    beta: number;
}

export interface CubePart {
    name: string;
    cameraInfo: CameraInfo;
}

export interface CubePartVisualizationInfo {
    name: string;
    textColor: string;
    color: string;
}

const lightGray = '#ACACAC';
const darkGray = '#3e3e3e';

export class CubeViewController {

    constructor(private scene: BABYLON.Scene,
                private cubeCamera: BABYLON.ArcRotateCamera,
                private assetProvider: AssetsProvider,
                cameraToObserve: BABYLON.Camera) {
        this.otherCamera = cameraToObserve;
        this.init();
    }
    public parentNode: BABYLON.Mesh;
    private otherCamera: BABYLON.Camera;
    private currentOutlinedPart: BABYLON.AbstractMesh;
    public shouldRotate = false;
    public rotateFunction: any;
    private cubeParts = new Map<CubePartVisualizationInfo, CameraInfo>([
        [{name: 'top', textColor: darkGray, color: lightGray}, {alpha: Math.PI / 2, beta: 2 * Math.PI}],
        [{name: 'bottom', textColor: darkGray, color: lightGray}, {alpha: Math.PI / 2, beta: Math.PI}],
        [{name: 'right', textColor: darkGray, color: lightGray}, {alpha: 2 * Math.PI, beta: Math.PI / 2}],
        [{name: 'left', textColor: darkGray, color: lightGray}, {alpha: Math.PI, beta: Math.PI / 2}],
        [{name: 'front', textColor: darkGray, color: lightGray}, {alpha: -Math.PI / 2, beta: Math.PI / 2}],
        [{name: 'back', textColor: darkGray, color: lightGray}, {alpha: Math.PI / 2, beta: Math.PI / 2}],
        [{name: 'top-front', textColor: lightGray, color: darkGray}, {alpha: -Math.PI / 2, beta: Math.PI / 4}],
        [{name: 'top-back', textColor: lightGray, color: darkGray}, {alpha: Math.PI / 2, beta: Math.PI / 4}],
        [{name: 'top-right', textColor: lightGray, color: darkGray}, {alpha: 2 * Math.PI, beta: Math.PI / 4}],
        [{name: 'top-left', textColor: lightGray, color: darkGray}, {alpha: Math.PI, beta: Math.PI / 4}],
        [{name: 'top-front-right', textColor: darkGray, color: lightGray}, {alpha: -Math.PI / 4, beta: Math.PI / 4}],
        [{name: 'top-front-left', textColor: darkGray, color: lightGray}, {alpha: -3 * Math.PI / 4, beta: Math.PI / 4}],
        [{name: 'top-back-right', textColor: darkGray, color: lightGray}, {alpha: Math.PI / 4, beta: Math.PI / 4}],
        [{name: 'top-back-left', textColor: darkGray, color: lightGray}, {alpha: 3 * Math.PI / 4, beta: Math.PI / 4}],
        [{name: 'bottom-front', textColor: lightGray, color: darkGray}, {alpha: -Math.PI / 2, beta: 3 * Math.PI / 4}],
        [{name: 'bottom-back', textColor: lightGray, color: darkGray}, {alpha: Math.PI / 2, beta: 3 * Math.PI / 4}],
        [{name: 'bottom-right', textColor: lightGray, color: darkGray}, {alpha: 2 * Math.PI, beta: 3 * Math.PI / 4}],
        [{name: 'bottom-left', textColor: lightGray, color: darkGray}, {alpha: Math.PI, beta: 3 * Math.PI / 4}],
        [{name: 'bottom-front-right', textColor: darkGray, color: lightGray}, {
            alpha: -Math.PI / 4,
            beta: 3 * Math.PI / 4
        }],
        [{name: 'bottom-front-left', textColor: darkGray, color: lightGray}, {
            alpha: -3 * Math.PI / 4,
            beta: 3 * Math.PI / 4
        }],
        [{name: 'bottom-back-right', textColor: darkGray, color: lightGray}, {
            alpha: Math.PI / 4,
            beta: 3 * Math.PI / 4
        }],
        [{name: 'bottom-back-left', textColor: darkGray, color: lightGray}, {
            alpha: 3 * Math.PI / 4,
            beta: 3 * Math.PI / 4
        }],
        [{name: 'front-right', textColor: lightGray, color: darkGray}, {alpha: -Math.PI / 4, beta: Math.PI / 2}],
        [{name: 'front-left', textColor: lightGray, color: darkGray}, {alpha: 5 * Math.PI / 4, beta: Math.PI / 2}],
        [{name: 'back-right', textColor: lightGray, color: darkGray}, {alpha: Math.PI / 3, beta: Math.PI / 2}],
        [{name: 'back-left', textColor: lightGray, color: darkGray}, {alpha: 2 * Math.PI / 3, beta: Math.PI / 2}]
    ]);
    private meshesMap = new Map<BABYLON.Mesh, CameraInfo>();
    private fullscreenGUI: GUI.AdvancedDynamicTexture;

    private lazyInitControls = false;

    init() {
        this.parentNode = new BABYLON.Mesh('cube-view', this.scene);

        this.parentNode.scaling = new BABYLON.Vector3(1.5, 1.5, 1.5);
        this.cubeParts.forEach((v, part, m) => {
            this.assetProvider.getMesh(part.name).then(
                (mesh) => {
                    const partMesh = new BABYLON.Mesh(part.name, this.scene);
                    partMesh.parent = this.parentNode;
                    const vertexData = BABYLON.VertexData.ExtractFromMesh(mesh, true, true);
                    vertexData.applyToMesh(partMesh);
                    partMesh.metadata = {name: part, cameraInfo: v};
                    const meshTexture = new BABYLON.DynamicTexture(part + ' ' + 'Texture', {
                        width: 512,
                        height: 512
                    }, this.scene, true);
                    const font = 'bold 126px monospace';
                    const material = new BABYLON.StandardMaterial('cubeMaterial', this.scene);
                    material.fillMode = BABYLON.Material.ClockWiseSideOrientation;
                    material.ambientColor = new BABYLON.Color3(1, 1, 1);
                    material.diffuseColor = new BABYLON.Color3(1, 1, 1);
                    const context = meshTexture.getContext();
                    meshTexture.drawText(part.name, 10 + ((8 - part.name.length) * 28), 256, font, part.textColor, part.color, true, true);
                    meshTexture.update();
                    material.ambientTexture = meshTexture;
                    material.disableLighting = true;
                    partMesh.material = material;
                    partMesh.isPickable = true;
                    this.meshesMap.set(partMesh, v);
                    this.createGUICubeControls();
                }
            );
        });
        this.initializeCameraObservation();
    }

    private createGUICubeControls() {
        if (!this.lazyInitControls) {
            this.lazyInitControls = true;
            const rotationSpeed = 1;
            const rotationValue = 1 / ((2 * Math.PI) / (rotationSpeed / 10));
            this.fullscreenGUI = GUI.AdvancedDynamicTexture.CreateFullscreenUI('fullscreenCubeGUI', true, this.scene);
            const grid = new GUI.Grid();
            grid.width = '100%';
            grid.height = '100%';
            grid.addColumnDefinition(0.33);
            grid.addColumnDefinition(0.33);
            grid.addColumnDefinition(0.33);
            grid.addRowDefinition(0.33);
            grid.addRowDefinition(0.33);
            grid.addRowDefinition(0.33);
            const upButton = this.createButton('upButton',
                GUI.Control.HORIZONTAL_ALIGNMENT_CENTER,
                GUI.Control.VERTICAL_ALIGNMENT_CENTER,
                0,
                'beta',
                -rotationValue);
            const downButton = this.createButton('downButton',
                GUI.Control.HORIZONTAL_ALIGNMENT_CENTER,
                GUI.Control.VERTICAL_ALIGNMENT_CENTER,
                Math.PI,
                'beta',
                rotationValue);
            const leftButton = this.createButton('leftButton',
                GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT,
                GUI.Control.VERTICAL_ALIGNMENT_CENTER,
                -Math.PI / 2,
                'alpha',
                -rotationValue
            );
            const rightButton = this.createButton('rightButton',
                GUI.Control.HORIZONTAL_ALIGNMENT_LEFT,
                GUI.Control.VERTICAL_ALIGNMENT_CENTER,
                Math.PI / 2,
                'alpha',
                rotationValue
            );
            const leftUpperButton = this.createRotateWithAnimationButton('leftRotateArrow.png',
                'leftUpper',
                GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT,
                GUI.Control.VERTICAL_ALIGNMENT_CENTER,
                -Math.PI / 2);
            const rightUpperButton = this.createRotateWithAnimationButton('rightRotateArrow.png',
                'rightUpper',
                GUI.Control.HORIZONTAL_ALIGNMENT_LEFT,
                GUI.Control.VERTICAL_ALIGNMENT_CENTER,
                Math.PI / 2);
            grid.addControl(upButton, 0, 1);
            grid.addControl(downButton, 2, 1);
            grid.addControl(leftButton, 1, 0);
            grid.addControl(rightButton, 1, 2);
            grid.addControl(leftUpperButton, 0, 0);
            grid.addControl(rightUpperButton, 0, 2);
            this.fullscreenGUI.addControl(grid);
        }
    }

    private initializeCameraObservation() {
        this.cubeCamera.onViewMatrixChangedObservable.add((e, s) => {
            if ((<RotateCamera>this.otherCamera).alpha !== this.cubeCamera.alpha) {
                (<RotateCamera>this.otherCamera).alpha = this.cubeCamera.alpha;
            }

            if ((<RotateCamera>this.otherCamera).beta !== this.cubeCamera.beta) {
                (<RotateCamera>this.otherCamera).beta = this.cubeCamera.beta;
            }
        });
        this.otherCamera.onViewMatrixChangedObservable.add((e, s) => {
            if ((<RotateCamera>this.otherCamera).alpha !== this.cubeCamera.alpha) {
                this.cubeCamera.alpha = (<RotateCamera>this.otherCamera).alpha;
            }

            if ((<RotateCamera>this.otherCamera).beta !== this.cubeCamera.beta) {
                this.cubeCamera.beta = (<RotateCamera>this.otherCamera).beta;
            }
        });
    }

    private createButton(name: string, horizontalAlignment: number, verticalAlignment: number, rotation: number, cameraParameter: string, rotationValue: number): GUI.Button {
        const button = GUI.Button.CreateImageOnlyButton(name, 'assets/triangle.png');
        button.width = '50%';
        button.height = '50%';
        button.color = 'white';
        button.thickness = 0;
        button.horizontalAlignment = horizontalAlignment;
        button.verticalAlignment = verticalAlignment;
        button.rotation = rotation;
        button.isHitTestVisible = true;
        button.onPointerDownObservable.add((e, s) => {
            if (this.otherCamera && this.cubeCamera) {
                this.shouldRotate = true;
                this.rotateFunction = function () {
                    this.cubeCamera[cameraParameter] += rotationValue;
                    this.otherCamera[cameraParameter] += rotationValue;
                };
            }
        });
        button.onPointerUpObservable.add((e, s) => {
            if (this.otherCamera && this.cubeCamera) {
                this.shouldRotate = false;
            }
        });

        return button;
    }

    private createRotateWithAnimationButton(imageName: string, name: string, horizontalAlignment: number, verticalAlignment: number, rotationValue: number): GUI.Button {
        const button = GUI.Button.CreateImageOnlyButton(name, 'assets/' + imageName);
        button.width = '80%';
        button.height = '80%';
        button.color = 'white';
        button.thickness = 0;
        button.horizontalAlignment = horizontalAlignment;
        button.verticalAlignment = verticalAlignment;
        button.onPointerClickObservable.add((e, s) => {
            if (this.otherCamera && this.cubeCamera) {
                this.createRotateCamerasWithButtonAnimation('alpha', rotationValue);
            }
        });
        return button;
    }

    private createRotateCamerasWithButtonAnimation(parameter: string, rotationValue: number) {
        const easingFunction = new BABYLON.CubicEase();
        easingFunction.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

        const alphaCubeAnimation = this.createSimpleRotateAnimation(parameter, rotationValue);
        const alphaOtherCameraAnimation = this.createSimpleRotateAnimation(parameter, rotationValue);
        alphaCubeAnimation.setEasingFunction(easingFunction);
        alphaOtherCameraAnimation.setEasingFunction(easingFunction);
        this.cubeCamera.animations.push(alphaCubeAnimation);
        this.otherCamera.animations.push(alphaOtherCameraAnimation);


        this.scene.beginAnimation(this.cubeCamera, 0, 100, false);
        this.otherCamera.getScene().beginAnimation(this.otherCamera, 0, 100, false);
    }

    private createSimpleRotateAnimation(parameter: string, rotationValue): BABYLON.Animation {
        const animation = new BABYLON.Animation('cameraRotateTo', parameter, 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT);
        const value = this.cubeCamera[parameter] + rotationValue;
        const keys = [
            {
                frame: 0,
                value: this.cubeCamera[parameter]
            },
            {
                frame: 60,
                value: value
            }
        ];
        animation.setKeys(keys);
        return animation;
    }

    public rotateCameras(eventData: BABYLON.PointerInfo, eventState: BABYLON.EventState) {
        if (this.otherCamera && this.cubeCamera) {
            if (eventData.type === BABYLON.PointerEventTypes.POINTERTAP) {
                const pickInfo = this.scene.pick(this.scene.pointerX, this.scene.pointerY, null, null, this.cubeCamera);
                if (pickInfo.pickedMesh) {
                    const cubeInfo: CubePart = pickInfo.pickedMesh.metadata;
                    if (cubeInfo) {
                        if ((<BABYLON.ArcRotateCamera>this.otherCamera).alpha && (<BABYLON.ArcRotateCamera>this.otherCamera).beta) {
                            this.rotateCamerasWithAnimation(cubeInfo.cameraInfo.alpha, cubeInfo.cameraInfo.beta);
                        }
                    }
                }
            } else if (eventData.type === BABYLON.PointerEventTypes.POINTERMOVE) {
                const pickInfo = this.scene.pick(this.scene.pointerX, this.scene.pointerY, null, null, this.cubeCamera);
                if (pickInfo.pickedMesh) {
                    const cubeInfo: CubePart = pickInfo.pickedMesh.metadata;
                    if (cubeInfo) {
                        if (this.currentOutlinedPart !== pickInfo.pickedMesh) {
                            if (this.currentOutlinedPart) {
                                this.currentOutlinedPart.disableEdgesRendering();
                            }
                            this.currentOutlinedPart = pickInfo.pickedMesh;
                            this.currentOutlinedPart.enableEdgesRendering();
                            this.currentOutlinedPart.edgesWidth = 4.0;
                            this.currentOutlinedPart.edgesColor = new BABYLON.Color4(1, 0, 0, 1);
                        }
                    }
                }
            }
        } else {
            console.error('Missing cameras: ');
            console.error('Cube View Camera: ' + this.cubeCamera);
            console.error('Camera To Observe: ' + this.otherCamera);
        }
    }

    private createRotateCameraToAnimation(parameter: string, rotationFactor: number): BABYLON.Animation {
        const animation = new BABYLON.Animation('cameraRotateTo', parameter, 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT);
        const multiplification = this.cubeCamera[parameter] / (2 * Math.PI);
        const multiplier = Math.abs(multiplification - Math.floor(multiplification)) > 0.5 ? Math.ceil(multiplification) : Math.floor(multiplification);
        const upperValue = (multiplier * (2 * Math.PI)) + rotationFactor;
        const lowerValue = upperValue - (2 * Math.PI);
        const value = Math.abs(upperValue - this.cubeCamera[parameter]) < Math.abs(lowerValue - this.cubeCamera[parameter]) ? upperValue : lowerValue;

        const keys = [
            {
                frame: 0,
                value: this.cubeCamera[parameter]
            },
            {
                frame: 60,
                value: value
            }
        ];
        animation.setKeys(keys);
        return animation;
    }

    private rotateCamerasWithAnimation(alpha: number, beta: number) {
        const easingFunction = new BABYLON.CubicEase();
        easingFunction.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

        // cube camera animations
        const alphaCubeAnimation = this.createRotateCameraToAnimation('alpha', alpha);
        const betaCubeAnimation = this.createRotateCameraToAnimation('beta', beta);
        alphaCubeAnimation.setEasingFunction(easingFunction);
        betaCubeAnimation.setEasingFunction(easingFunction);
        this.cubeCamera.animations.push(alphaCubeAnimation);
        this.cubeCamera.animations.push(betaCubeAnimation);

        // other camera animations
        const alphaOtherCameraAnimation = this.createRotateCameraToAnimation('alpha', alpha);
        const betaOtherCameraAnimation = this.createRotateCameraToAnimation('beta', beta);
        alphaOtherCameraAnimation.setEasingFunction(easingFunction);
        betaOtherCameraAnimation.setEasingFunction(easingFunction);
        this.otherCamera.animations.push(alphaOtherCameraAnimation);
        this.otherCamera.animations.push(betaOtherCameraAnimation);

        this.scene.beginAnimation(this.cubeCamera, 0, 100, false);
        this.otherCamera.getScene().beginAnimation(this.otherCamera, 0, 100, false);
    }
}
