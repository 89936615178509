import * as BABYLON from 'babylonjs';
import {AssetsProvider, BabylonAssetsProvider} from '../providers';
import {Webcad} from './webcad';
import {ModelVisualizer} from '../visualizers';
import {CubeView3d, RotateCamera} from '../components';
import {HtmlLayer} from '../html-layer/html-layer';
import {ViewState} from '../models/view-state.model';
import {babylonToCamera} from '../utils/cast';
import {BehaviorSubject, Observable} from 'rxjs';

export class View3D<ModelType> implements Webcad {
    protected camera: BABYLON.Camera;
    public assets: AssetsProvider;
    protected cubeView3D: CubeView3d;
    public shouldUpdate: boolean = true;
    public isAnimationPlaying: boolean = false;

    public engine: BABYLON.Engine;
    public scene: BABYLON.Scene;
    public htmlLayer: HtmlLayer;

    public viewState: ViewState;//immutable
    public model: ModelType;//immutable
    private modelVisualizer: ModelVisualizer<ModelType>;


    private resolveInit: (result:boolean) => void;
    private rejectInit: (reason?: any) => void;
    public isReady: Promise<boolean> = new Promise<boolean>((resolve, reject) => {
        this.resolveInit = resolve;
        this.rejectInit = reject;
    });
    private dirtyModel: boolean = false;
    private dirtyViewState: boolean = false;
    private dirtyCamera: boolean = true;
    private _onBeforeRender = new BehaviorSubject<BABYLON.Camera>(null);

    constructor(private canvas: any,
                assetsRootPath: string = null,
                assetsFile: string = null,
                texturesRootPath: string = null) {
        this.engine = new BABYLON.Engine(this.canvas, true);
        this.scene = new BABYLON.Scene(this.engine);
        this.assets = new BabylonAssetsProvider(this.engine, assetsRootPath, assetsFile, texturesRootPath);
        this.scene.ambientColor = new BABYLON.Color3(1, 1, 1);
    }

    public get onBeforeRender(): Observable<BABYLON.Camera> {
        return this._onBeforeRender;
    }

    public init(model: ModelType, modelVisualizer: ModelVisualizer<ModelType>): void {
        this.model = model;
        this.viewState = {
            camera: babylonToCamera(this.scene.activeCamera),
            canvasSize: {
                width: this.canvas.clientWidth,
                height: this.canvas.clientHeight,
            }
        };
        this.modelVisualizer = modelVisualizer;

        let rootNode = new BABYLON.Node('Root', this.scene);

        modelVisualizer.init(rootNode, model, this)
            .then(
                () => {
                    (this.camera as RotateCamera).onCameraChangedObservable.add(() => {
                        this.dirtyCamera = true;
                    });
                    this.engine.runRenderLoop(this.render.bind(this));
                    this.resolveInit(true);
                },
                (error) => {
                    this.rejectInit();
                    console.error(error);
                    throw new Error('Visualization init failed: ' + error.toString());
                }
            );
    }


    protected render() {
        this.updateCamera();
        this.updateCanvasSize();
        this.updateAnimationFlag();
        this.updateVisualization();
        this.updateHtmlLayer();
        if (this.shouldUpdate) {
            this._onBeforeRender.next(this.camera);
            this.engine.clear(null, true, true, true);
            this.scene.render();
            this.shouldUpdate = false;
            if (this.camera instanceof RotateCamera) {
                this.camera.isDirty = false;
            }
        }
    }

    public forceModelRender(model: ModelType) {
        var oldModel: ModelType = this.cloneObject<ModelType>(this.model);
        this.updateModel(model);
        this.render();
        this.updateModel(oldModel);
        // this.updateVisualization();
    }


    public updateVisualization() {
        if (this.dirtyModel || this.dirtyViewState) {
            this.modelVisualizer.updateVisualization(this.model);
            this.shouldUpdate = true;
            this.dirtyModel = false;
            this.dirtyViewState = false;
        }
    }

    private updateAnimationFlag(): void {
        if (this.scene._activeAnimatables.length > 0 || (this.cubeView3D && this.cubeView3D.scene._activeAnimatables.length > 0)) {
            this.isAnimationPlaying = true;
            this.shouldUpdate = true;
        } else {
            this.isAnimationPlaying = false;
        }
    }

    private updateCamera() {
        if (this.camera instanceof RotateCamera) {
            if (this.dirtyCamera) {
                this.viewState = {
                    ...this.viewState,
                    camera: babylonToCamera(this.camera)
                };
                this.dirtyCamera = false;
                this.dirtyViewState = true;
            }
        }
    }

    private updateCanvasSize() {
        if (this.viewState.canvasSize.width !== this.canvas.clientWidth || this.viewState.canvasSize.height !== this.canvas.clientHeight) {
            this.viewState = {
                ...this.viewState,
                canvasSize: {
                    width: this.canvas.clientWidth,
                    height: this.canvas.clientHeight,
                }
            };
            this.dirtyViewState = true;
            this.dirtyCamera = true;
        }
    }

    public updateModel(model: ModelType): void {
        this.model = model;
        this.dirtyModel = true;
    }

    private updateHtmlLayer() {
        if (!!this.htmlLayer) {
            this.htmlLayer.update(this.viewState.canvasSize);
        }
    }

    public getCamera() {
        return this.camera;
    }

    public getCanvas() {
        return this.canvas;
    }

    public getEngine() {
        return this.engine;

    }

    public setCubeView(cubeView: CubeView3d) {
        this.cubeView3D = cubeView;
    }

    public dispose(): void {
        this.modelVisualizer.dispose();
        this.camera.dispose(false, true);
        this.scene.dispose();
        this.engine.dispose();
        if (!!this.htmlLayer) {
            this.htmlLayer.dispose();
        }
    }

    public getHtmlLayer(): HtmlLayer {
        if (!this.htmlLayer) {
            this.htmlLayer = new HtmlLayer(this.canvas);
        }
        return this.htmlLayer;
    }

    public cloneObject<T>(obj: T): T {
        const clone = {} as T;
        const keys = Object.keys(obj);
        for (const k of keys) {
            if (!(obj[k] instanceof Object)) {
                clone[k] = obj[k];
            } else if (obj[k] instanceof Map) {
                clone[k] = new Map<any, any>(Array.from(obj[k].entries()));
            } else if (obj[k] instanceof Array) {
                clone[k] = [...obj[k]];
            } else {
                clone[k] = this.cloneObject(obj[k]);
            }
        }
        return clone;
    }

}
