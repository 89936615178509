import {Perforation, sizeStringToPerforationSize} from './perforation.model';
import {ExtendedMetal} from './extended-metal.model';
import {Mesh} from './mesh.model';
import {Material} from './material.model';
import {Surface} from './surface.model';
import {Configuration} from './configuration.model';
import {Position} from './position.model';
import {Element} from './element.model';
import { WallpaperControl } from './wallpaper.model';
import {PerforationCustomGeom} from './perforation-custom-geom';
import {Dataset} from '../dataset/dataset.model';
import { SelectedDesignerEffect } from '../effect.model';
import { SelectedFading } from '../fading.model';
import { SelectedPerfaction, SelectedMultiSizePattern } from '../perfaction.model';
import { PerforationType } from './perforation-type.model';

export enum ProductType {
  ExtendedMetals = 0, // E
  PerforatedSheets = 1, // A
  CrimpedMesh = 2, // F
  WeldedMesh = 3, // G
  WovenWireMesh = 4 // D
}

export function getProductTypeFromCode(code: string): ProductType {
  // based on documentation: point 4.3
  switch (code.toUpperCase()) {
    case 'E':
      return ProductType.ExtendedMetals;
    case 'A':
      return ProductType.PerforatedSheets;
    case 'F':
      return ProductType.CrimpedMesh;
    case 'G':
      return ProductType.WeldedMesh;
    case 'D':
      return ProductType.WovenWireMesh;
    default:
      return null;
  }
}

export enum Step {
  cockpit,
  template,
  shape,
  pattern,
  attachment,
  design,
  import,
  import_pattern,
  import_attachment
}

export interface Format {
  type: string; // "individual"
  name: string; // "individual"
  size: string; // ""
}

export interface ProductConfiguration {
  configId: string;
  isMevacoUser: string;
  isTrustedOrganizationUnit: string;
  isDxfAllowedOrganizationUnit: string;
  format: Format;
  productType: ProductType | null;
  perforation: Perforation;
  activePerforation: PerforationType;
  customPerforation: boolean;
  effect: SelectedDesignerEffect;
  fading: SelectedFading;
  perfaction: SelectedPerfaction;
  multiSizePattern: SelectedMultiSizePattern;
  wallpaper: WallpaperControl;
  extendedMetal: ExtendedMetal;
  mesh: Mesh;
  material: Material;
  surface: Surface;
  configuration: Configuration;
  elements: Element[];
  maxElementLength: number;
  maxElementWidth: number;
  positions: Position[];
  step: Step;
  currentPosition: number;
  showStartPopup: boolean;
  loadTemplateModal: boolean;

  generateLabel: boolean;
  area: number;
  // activePerforation: 'classic' | 'wallpaper' | 'customPattern' | 'perfaction';
  specialDate: number;
  openArea?: number;
  totalSurface?: number;
  configHeadNote?: string;
}

export const initialProductConfiguration: ProductConfiguration = {
  configId: '',
  isMevacoUser: 'No',
  isTrustedOrganizationUnit: 'No',
  isDxfAllowedOrganizationUnit: 'No',
  activePerforation: PerforationType.CLASSIC,
  generateLabel: false,
  format: {
    type: 'individual',
    name: 'individual',
    size: ''
  },
  wallpaper: {
    name: '',
    zoomEnabled: 1,
    halfHolesEnabled: 0,
    group: '',
    groupSort: 0,
    sort: 0,
    zoomMax: 2,
    zoomMin: 0.5
  },
  productType: null, // must be null as initial!
  customPerforation: false,
  perforation: {
    geometryType: '',
    perforationType: '',
    size: '',
    spacing: '',
    format: '',
    format1: '',
    format2: '',
    position: '',
    dependentMode: true,
    custom: {
      offsetX: 0,
      offsetY: 0,
      stumps: [null, null, null, null, null, null, null, null, null, null, null]
    }
    // rotation: 0
  },
  effect: null,
  fading: null,
  perfaction: {
    enabled: false,
    imageConfig: null,
    config: null,
    imageId: null
  },
  multiSizePattern: {
    form: '',
    label: '',
    format: '',
    holeSizes: []
  },
  extendedMetal: {
    type: '',
    size: '',
    feedrate: '',
    typeOfCut: '',
    openMesh: '',
    stegcluster: ''
  },
  mesh: {
    format: '',
    typeOfCut: '',
    openMesh: ''
  },
  material: {
    materialType: '',
    thickness: '',
    quality: '',
    foil: 'None'
  },
  surface: {
    surfaceType: '',
    colorSystem: '',
    colorGroup: '',
    color: '',
    undercoating: '',
    levelOfGloss: '',
    polishedSides: '',
  },
  configuration: {
    material: {id: 0},
    lomoe: {id: 0},
    ewz: {id: 0},
    minMaxRaender: {id: 0},
    stremoe: {id: 0},
    gitter: {id: 0},
    geomatrix: {id: 0},
    wallpaper: {id: 0},
    minFeedrate: 1,
    maxFeedrate: 99.9,
    maxWidth: 0,
    maxLength: 0,
    maxPlateWidth: 0,
    maxCoilLength: 0,
    maxCoilWidth: 0,
    maxPlateLength: 0,
    singleTools: [],
    allSingleToolsFound: false,
    isCustomPattern: false,
    minFoil: 2,
    minPolishing: 1,
    minPowderCoating: 1,
    minGalvanizing: 1,

  },
  elements: [],
  positions: [],
  step: Step.template,
  currentPosition: -1,
  showStartPopup: false,
  loadTemplateModal: false,
  area: 0,
  maxElementLength: 0,
  maxElementWidth: 0,
  specialDate: 0,
};

export function anyBendedElement(productConfiguration: ProductConfiguration): boolean {
  return !!productConfiguration.elements.find( el => el.breakLines && el.breakLines.length > 0);
}
/*
export function createPerforationCustomData(productConfiguration: ProductConfiguration, dataset: Dataset): PerforationCustomGeom {
  const result: PerforationCustomGeom = {
    offsetX: 0,
    offsetY: 0,
    stumps: [null, null, null, null, null, null, null, null, null, null, null]
  };

  let filtered = dataset.geomatrix.filter(x => x.lochungsart  === productConfiguration.perforation.perforationType)
    .sort((a, b) => a.codeLochanfang - b.codeLochanfang );
  if (productConfiguration.perforation.position) {
    filtered = filtered.filter(x => x.codeLochanfang === +productConfiguration.perforation.position );
  }
  const geomatrix = filtered[0] as any;

  if (geomatrix) {
    const size = sizeStringToPerforationSize(productConfiguration.perforation.size);
    const calculatedSchrittweite = calculateSchrittweite(productConfiguration);
    const lomoe: any = productConfiguration.configuration.lomoe || {};
    const lomeSchrittweiteLfx = lomoe.loSchrittweiteLfx || calculatedSchrittweite.lfx;
    const lomeSchrittweiteLfy = lomoe.loSchrittweiteLfy || calculatedSchrittweite.lfy;

    for (let i = 0; i < geomatrix.matrixzaehler; i++) {
      const n = i + 1;
      const ind: string = (n) < 10 ? '0' + (n).toString() : (n).toString();
      const schrittweiteLfy = geomatrix['schrittweiteLfy' + ind] || lomeSchrittweiteLfy;
      const schrittweiteLfx = geomatrix['schrittweiteLfx' + ind] || lomeSchrittweiteLfx;
      result.offsetX = Math.max( result.offsetX, schrittweiteLfx );
      result.offsetY = Math.max( result.offsetY, schrittweiteLfy );
    }

    for (let i = 0; i < geomatrix.matrixzaehler; i++) {
      const n = i + 1;
      const ind: string = (n) < 10 ? '0' + (n).toString() : (n).toString();

      const schrittweiteLfy = geomatrix['schrittweiteLfy' + ind] || lomeSchrittweiteLfy;
      const schrittweiteLfx = geomatrix['schrittweiteLfx' + ind] || lomeSchrittweiteLfx;
      const startLfy = geomatrix['startLfy' + ind];
      const startLfx = geomatrix['startLfx' + ind];
      const ensLfx = geomatrix['endeLfx' + ind];
      const ensLfy = geomatrix['endeLfy' + ind];
      const w = +geomatrix['w' + ind] || size.width;
      let l = +geomatrix['l' + ind] || size.length;
      const form = geomatrix['form' + ind];
      const rotation = geomatrix['stempellage' + ind];

      let singleTool = dataset.singleTools.find( st => st.form === form);
      if (singleTool == null) {
        singleTool = dataset.singleTools[0];
      }

      if (singleTool.lenght === 0) {
        l = 0;
      }

      result.stumps[i] = {
        enabled: true,
        w,
        l,
        offsetDivX: result.offsetX / schrittweiteLfx,
        offsetDivY: result.offsetY / schrittweiteLfy,
        formType: singleTool.formGroup,
        form,
        startX: startLfx,
        startY: startLfy,
        endX: ensLfx,
        endY: ensLfy,
        rotation
      };
    }
  }
  return result;
}

function calculateSchrittweite(productConfiguration: ProductConfiguration): {lfx: number, lfy: number} {
  const perforation = productConfiguration.perforation;
  const p1 = Number(perforation.format1);
  const p2 = Number( perforation.format2 === '' ? perforation.format1 : perforation.format2);
  function round(v: number): number {
    return Math.round(v * 100) / 100;
  }
  const result = {
    lfx: p2,
    lfy: p1
  };
  switch (perforation.perforationType) {
    case 'Lvl':
    case 'Lvel':
      result.lfx = p2;
      result.lfy = p1 * 2;
      break;
    case 'Lvq':
    case 'Lveq':
      result.lfx = p1 * 2;
      result.lfy = p2;
      break;
    case 'Lgq':
    case 'Lgeq':
      result.lfx = p1;
      result.lfy = p2;
      break;
    case 'Lgl':
    case 'Lgel':
      result.lfx = p2;
      result.lfy = p1;
      break;
    case 'Qg':
      result.lfx = p1;
      result.lfy = p1;
      break;
    case 'Qd':
    case 'Rd':
      result.lfx = round(p1 * 0.707) * 2;
      result.lfy = round(p1 * 0.707) * 2;
      break;
    case 'Qv':
      result.lfx = p1;
      result.lfy = p1 * 2;
      break;
    case 'Qv90':
      result.lfx = p1 * 2;
      result.lfy = p1;
      break;
    case 'Rv':
    case 'Hv':
      result.lfx = p1;
      result.lfy = round(p1 * 0.866) * 2;
      break;
    case 'Rg':
      result.lfx = p1;
      result.lfy = p1;
      break;
    case 'Rv90':
      result.lfx = round(p1 * 0.866) * 2;
      result.lfy = p1;
      break;
  }
  return result;
}
*/

export function getExpandedMetalGltfHash(productConfiguration: ProductConfiguration): string {
  if (
    productConfiguration.productType === ProductType.ExtendedMetals &&
    productConfiguration.configuration?.stremoe?.id !== 0 &&
    !!productConfiguration.extendedMetal.feedrate &&
    !!productConfiguration.material.thickness
  ) {

    const stremoe = productConfiguration.configuration?.stremoe;
    if (stremoe.wkzFlaechespitze) {
      const strand = +productConfiguration.extendedMetal.feedrate;
      const minS = +stremoe.vorschubMin.replace(',', '.');
      const maxS = +stremoe.vorschubMax.replace(',', '.');
      const offset = +stremoe.querversatz.replace(',', '.');
      let t = 1.0;
      if (minS !== maxS) {
        t = (strand - minS) / (maxS - minS);
      }
      const totalThickness = stremoe.gDickeMin * (1.0 - t) + stremoe.gDickeMax * t;

      const dtMin = minS + stremoe.minThickness;
      const dtMax = maxS + stremoe.maxThickness;
      const dt = dtMax === dtMin ? 0 : ((strand + +productConfiguration.material.thickness - dtMin) / (dtMax - dtMin));
      const divingDepth = stremoe.divingDepthMax * (1 - dt) + stremoe.divingDepthMin * (dt);
      // const divingDepth = ((stremoe.sizeS - strand - +productConfiguration.material.thickness) / 2 * 1.1);

      let type = '';
      let hBond = 0;
      if (productConfiguration.extendedMetal.type === 'Raute') {
        hBond = 2.0;
        type = 'rhomb';
      } else if (productConfiguration.extendedMetal.type === 'Sechseck') {
        type = 'hexagonal';
      } else if (productConfiguration.extendedMetal.type === 'Welle') {
        type = 'wave';
      }
      return `type_${type}_lwd_${stremoe.sizeL}_swd_${stremoe.sizeS}_sw_${productConfiguration.material.thickness}_strand_${strand}_tipTool_${stremoe.wkzFlaechespitze}_divingDepth_${divingDepth.toFixed(1)}_toolAngle_${stremoe.wkzZahnwinkel}_totalThickness_${totalThickness}_hBond_${hBond}_offset_${offset}`;

    } else {
      console.warn('No wkzFlaechespitze: ' + stremoe.id);
    }
  }
  return null;
}
