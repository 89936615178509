import * as BABYLON from 'babylonjs';
import {Vector4} from '../math/vector4';
import colorFragment from '../glsl/color.fragment.glsl';
import measurementVertex from '../glsl/measurement.vertex.glsl';

export class MeasurementMaterial extends BABYLON.ShaderMaterial {
    constructor(scene: BABYLON.Scene) {
        super(
            'Angle-Measurement',
            scene,
            getShaderPath(),
            {
                attributes: ['position'],
                uniforms: ['worldViewProjection', 'length', 'distance', 'scale']
            });

        this.distance = 0.3;
        this.length = 1;
        this.scale = 0.1;
        this.color = {x: 0, y: 0, z: 0, w: 1};
    }

    public set distance(v: number) {
        this.setFloat('distance', v);
    }

    public set length(v: number) {
        this.setFloat('length', v);
    }

    public set scale(v: number) {
        this.setFloat('scale', v);
    }

    public set color(v: Vector4) {
        this.setVector4('matColor', new BABYLON.Vector4(v.x, v.y, v.z, v.w));
    }
}

function getShaderPath(): any {
    if (!BABYLON.Effect.ShadersStore['measurementVertexShader']) {
        BABYLON.Effect.ShadersStore['measurementVertexShader'] = measurementVertex;
    }
    if (!BABYLON.Effect.ShadersStore['customColorFragmentShader']) {
        BABYLON.Effect.ShadersStore['customColorFragmentShader'] = colorFragment;
    }
    return {
        vertex: 'measurement',
        fragment: 'customColor'
    };
}
