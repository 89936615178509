import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';
import { combineLatest, from, Observable, of } from 'rxjs';
import {debounceTime, map, switchMap, tap} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { getMaterialThickness } from '../model/dataset/dataset.model';
import { sizeToString } from '../model/dataset/perforation-format.model';
import {
  getCustomStampsParamsJson,
  getDataSetState,
  getIsCustomPerforation,
  getMultiSizePatternFormat,
  getMultiSizePatternPerforationPattern,
  getPerforationFormat,
  getPerforationSize,
  getPerforationType,
  getProductPerforationType,
  getProductType,
  getSelectedCustomStampFrom,
  getSelectedCustomStampW, getSelectedExpandedMetalFeedrate,
  getSelectedExpandedMetalSizeA, getSelectedExpandedMetalSizeAir, getSelectedExpandedMetalSizeB,
  getSelectedExpandedMetalSizeDecode,
  getSelectedExpandedMetalType,
  getSelectedMaterialCode,
  getSelectedMaterialThickness,
  getSelectedMaterialType,
  getSelectedMultiSizePatternBiggestHole,
  getWallpaperName,
  MevacoState,
} from '../store/reducers';
import {ProductType} from '../model/product-configuration/product-configuration.model';

interface Ceche {
  [request: string]: Promise<string[]>;
}

interface Param {
  selector: any;
  name: string;
  required: boolean;
}

@Injectable()
export class PossibleOptionsService {
  private ceche: Ceche = {};

  public possiblePerforations: Observable<string[]>;
  public possibleWallpapers: Observable<string[]>;
  public possiblePerforationsSizes: Observable<string[]>;
  public possiblePerforationFormats: Observable<string[]>;
  public possiblePerforationPositions: Observable<string[]>;
  public possibleMaterialTypes: Observable<string[]>;
  public possiblePerforationMaterialCodes: Observable<string[]>;
  public possiblePerfactionMaterialCodes: Observable<string[]>;
  public possibleExtendedMetalsMaterialCodes: Observable<string[]>;
  public thicknessOptions: Observable<string[]>;
  public possibleMaterialThicknesses: Observable<string[]>;
  public possibleWallpaperMaterialTypes: Observable<string[]>;
  public possiblePerforationMaterialTypes: Observable<string[]>;
  public possiblePerfractionMaterialTypes: Observable<string[]>;
  public possibleExpandedMetalMaterialTypes: Observable<string[]>;

  public possibleWallpaperMaterialCodes: Observable<string[]>;
  public possibleMaterialCodes: Observable<string[]>;
  public possibleWallpaperThicknessesRange: Observable<any>;
  public possiblePerforationThicknessesRange: Observable<any>;

  public possiblePerfactionThicknessesRange: Observable<any>;
  public possibleExtendedMetalThicknessesRange: Observable<any>;

  public possibleCustomPatternMaterialTypes: Observable<string[]>;
  public possibleCustomPatternMaterialCodes: Observable<string[]>;
  public possibleCustomPatternThicknessesRange: Observable<string[]>;
  public possibleCustomPatternForms: Observable<string[]>;
  public possibleCustomPatternW: Observable<string[]>;
  public possibleCustomPatternL: Observable<string[]>;

  public possibleExpandedMetalTypes: Observable<string[]>;
  public possibleExpandedMetalSizes: Observable<string[]>;


  constructor(private store: Store<MevacoState>, private http: HttpClient) {
    this.possiblePerforations = this.getPossibleOptionsObservable(
      'GetPossiblePerforations',
      [
        { name: 'code', selector: getSelectedMaterialCode, required: true },
        {
          name: 'thickness',
          selector: getSelectedMaterialThickness,
          required: true,
        },
      ]
    );

    this.possibleExpandedMetalTypes = this.getPossibleOptionsObservable(
      'GetPossibleExpandedMetalTypes',
      [
        { name: 'code', selector: getSelectedMaterialCode, required: true },
        {
          name: 'thickness',
          selector: getSelectedMaterialThickness,
          required: true,
        },
      ]
    );

    this.possibleExpandedMetalSizes = this.getPossibleOptionsObservable(
      'GetPossibleExpandedMetalSizes',
      [
        { name: 'code', selector: getSelectedMaterialCode, required: true },
        {
          name: 'thickness',
          selector: getSelectedMaterialThickness,
          required: true,
        },
        {
          name: 'type',
          selector: getSelectedExpandedMetalType,
          required: true,
        },
      ]
    );

    this.possibleWallpaperMaterialTypes = this.getPossibleOptionsObservable(
      'GetPossibleWallpaperMaterialTypes',
      [{ name: 'name', selector: getWallpaperName, required: true }]
    );

    this.possiblePerforationMaterialTypes = this.getPossibleOptionsObservable(
      'GetPossiblePerforationMaterialTypes',
      [
        { name: 'perf', selector: getPerforationType, required: true },
        { name: 'size', selector: getPerforationSize, required: true },
        { name: 'format', selector: getPerforationFormat, required: true },
      ]
    );

    this.possiblePerfractionMaterialTypes = this.getPossibleOptionsObservable(
      'GetPossiblePerforationMaterialTypes',
      [
        {
          name: 'perf',
          selector: getMultiSizePatternPerforationPattern,
          required: true,
        },
        {
          name: 'size',
          selector: createSelector(
            getSelectedMultiSizePatternBiggestHole,
            (hs) => sizeToString(hs)
          ),
          required: true,
        },
        { name: 'format', selector: getMultiSizePatternFormat, required: true },
      ]
    );

    this.possibleExpandedMetalMaterialTypes = this.getPossibleOptionsObservable(
      'GetPossibleExpandedMetalMaterialTypes',
      [
        {
          name: 'type',
          selector: getSelectedExpandedMetalType,
          required: true,
        },
        {
          name: 'a',
          selector: getSelectedExpandedMetalSizeA,
          required: true,
        },
        {
          name: 'b',
          selector: getSelectedExpandedMetalSizeB,
          required: true,
        },
        {
          name: 'air',
          selector: getSelectedExpandedMetalSizeAir,
          required: false,
        },
        {
          name: 'feedrate',
          selector: getSelectedExpandedMetalFeedrate,
          required: true,
        },
      ]
    );
    this.possibleCustomPatternMaterialTypes = this.getPossibleOptionsObservable(
      'GetPossibleCustomPatternMaterialTypes',
      [{ name: 'geom', selector: getCustomStampsParamsJson, required: true }]
    );

    this.possibleMaterialTypes = combineLatest([
      this.store.pipe(select(getProductType)),
      this.store.pipe(select(getProductPerforationType)),
      this.store.pipe(select(getIsCustomPerforation)),
    ]).pipe(
      switchMap(([productType, activePerforation, isCustomPattern]) => {
        if (productType === ProductType.PerforatedSheets) {
          if (activePerforation === 'wallpaper') {
            return this.possibleWallpaperMaterialTypes;
          } else if (activePerforation === 'classic') {
            if (isCustomPattern) {
              return this.possibleCustomPatternMaterialTypes;
            } else {
              return this.possiblePerforationMaterialTypes;
            }
          } else if (activePerforation === 'perfaction') {
            return this.possiblePerfractionMaterialTypes;
          }
        } else if (productType === ProductType.ExtendedMetals) {
          return this.possibleExpandedMetalMaterialTypes;
        }
        return of([]);
      })
    );

    this.possibleWallpaperMaterialCodes = this.getPossibleOptionsObservable(
      'GetPossibleWallpaperMaterialCodes',
      [
        { name: 'wallpaperName', selector: getWallpaperName, required: true },
        { name: 'material', selector: getSelectedMaterialType, required: true },
        {
          name: 'thickness',
          selector: getSelectedMaterialThickness,
          required: true,
        },
      ]
    );

    this.possiblePerforationMaterialCodes = this.getPossibleOptionsObservable(
      'GetPossiblePerforationMaterialCodes',
      [
        { name: 'perf', selector: getPerforationType, required: true },
        { name: 'size', selector: getPerforationSize, required: true },
        { name: 'format', selector: getPerforationFormat, required: true },
        {
          name: 'thickness',
          selector: getSelectedMaterialThickness,
          required: true,
        },
      ]
    );

    this.possiblePerfactionMaterialCodes = this.getPossibleOptionsObservable(
      'GetPossiblePerforationMaterialCodes',
      [
        {
          name: 'perf',
          selector: getMultiSizePatternPerforationPattern,
          required: true,
        },
        {
          name: 'size',
          selector: createSelector(
            getSelectedMultiSizePatternBiggestHole,
            (hs) => sizeToString(hs)
          ),
          required: true,
        },
        { name: 'format', selector: getMultiSizePatternFormat, required: true },
        {
          name: 'thickness',
          selector: getSelectedMaterialThickness,
          required: true,
        },
      ]
    );

    this.possibleExtendedMetalsMaterialCodes = this.getPossibleOptionsObservable(
      'GetPossibleExpandedMetalMaterialCodes',
      [
        {
          name: 'type',
          selector: getSelectedExpandedMetalType,
          required: true,
        },
        {
          name: 'a',
          selector: getSelectedExpandedMetalSizeA,
          required: true,
        },
        {
          name: 'b',
          selector: getSelectedExpandedMetalSizeB,
          required: true,
        },
        {
          name: 'air',
          selector: getSelectedExpandedMetalSizeAir,
          required: false,
        },
        {
          name: 'feedrate',
          selector: getSelectedExpandedMetalFeedrate,
          required: true,
        },
        {
          name: 'thickness',
          selector: getSelectedMaterialThickness,
          required: true,
        },
      ]
    );

    this.possibleCustomPatternMaterialCodes = this.getPossibleOptionsObservable(
      'GetPossibleCustomPatternMaterialCodes',
      [
        { name: 'geom', selector: getCustomStampsParamsJson, required: true },
        {
          name: 'materialType',
          selector: getSelectedMaterialType,
          required: true,
        },
        {
          name: 'thickness',
          selector: getSelectedMaterialThickness,
          required: true,
        },
      ]
    );

    // quality
    this.possibleMaterialCodes = combineLatest([
      this.store.pipe(select(getProductType)),
      this.store.pipe(select(getProductPerforationType)),
      this.store.pipe(select(getIsCustomPerforation)),
    ]).pipe(
      switchMap(([productType, activePerforation, isCustomPattern]) => {
        if (productType === ProductType.PerforatedSheets) {
          if (activePerforation === 'wallpaper') {
            return this.possibleWallpaperMaterialCodes;
          } else if (activePerforation === 'classic') {
            if (isCustomPattern) {
              return this.possibleCustomPatternMaterialCodes;
            } else {
              return this.possiblePerforationMaterialCodes;
            }
          } else if (activePerforation === 'perfaction') {
            return this.possiblePerfactionMaterialCodes;
          }
        } else if (productType === ProductType.ExtendedMetals) {
          return this.possibleExtendedMetalsMaterialCodes;
        }
      })
    );

    this.possibleWallpaperThicknessesRange = this.getPossibleOptionsObservable(
      'GetPossibleWallpaperThicknessesRange',
      [
        { name: 'wallpaperName', selector: getWallpaperName, required: true },
        {
          name: 'materialCode',
          selector: getSelectedMaterialType,
          required: true,
        },
      ]
    );

    this.possiblePerforationThicknessesRange =
      this.getPossibleOptionsObservable(
        'GetPossiblePerforationThicknessesRange',
        [
          { name: 'perf', selector: getPerforationType, required: true },
          { name: 'size', selector: getPerforationSize, required: true },
          { name: 'format', selector: getPerforationFormat, required: true },
          {
            name: 'materialCode',
            selector: getSelectedMaterialType,
            required: true,
          },
        ]
      );

    this.possiblePerfactionThicknessesRange = this.getPossibleOptionsObservable(
      'GetPossiblePerforationThicknessesRange',
      [
        {
          name: 'perf',
          selector: getMultiSizePatternPerforationPattern,
          required: true,
        },
        {
          name: 'size',
          selector: createSelector(
            getSelectedMultiSizePatternBiggestHole,
            (hs) => sizeToString(hs)
          ),
          required: true,
        },
        { name: 'format', selector: getMultiSizePatternFormat, required: true },
        {
          name: 'materialCode',
          selector: getSelectedMaterialType,
          required: true,
        },
      ]
    );


    this.possibleExtendedMetalThicknessesRange = this.getPossibleOptionsObservable(
      'GetPossibleExpandedMetalThicknessesRange',
      [
        {
          name: 'type',
          selector: getSelectedExpandedMetalType,
          required: true,
        },
        {
          name: 'a',
          selector: getSelectedExpandedMetalSizeA,
          required: true,
        },
        {
          name: 'b',
          selector: getSelectedExpandedMetalSizeB,
          required: true,
        },
        {
          name: 'air',
          selector: getSelectedExpandedMetalSizeAir,
          required: false,
        },
        {
          name: 'feedrate',
          selector: getSelectedExpandedMetalFeedrate,
          required: true,
        },
        {
          name: 'materialCode',
          selector: getSelectedMaterialType,
          required: true,
        },
      ]
    );

    this.possibleCustomPatternThicknessesRange =
      this.getPossibleOptionsObservable(
        'GetPossibleCustomPatternThicknessesRange',
        [
          { name: 'geom', selector: getCustomStampsParamsJson, required: true },
          {
            name: 'materialCode',
            selector: getSelectedMaterialType,
            required: true,
          },
        ]
      );


    this.possibleMaterialThicknesses = combineLatest([
      this.store.pipe(select(getProductType)),
      this.store.pipe(select(getProductPerforationType)),
      this.store.pipe(select(getIsCustomPerforation)),
    ]).pipe(
      switchMap(([productType, activePerforation, isCustomPattern]) => {
        let possibleThicknessesRange: Observable<any>;
        if (productType === ProductType.PerforatedSheets) {
          if (activePerforation === 'wallpaper') {
            possibleThicknessesRange = this.possibleWallpaperThicknessesRange;
          } else if (activePerforation === 'classic') {
            if (isCustomPattern) {
              possibleThicknessesRange = this.possibleCustomPatternThicknessesRange;
            } else {
              possibleThicknessesRange = this.possiblePerforationThicknessesRange;
            }
          } else if (activePerforation === 'perfaction') {
            possibleThicknessesRange = this.possiblePerfactionThicknessesRange;
          }
        } else if (productType === ProductType.ExtendedMetals) {
          possibleThicknessesRange = this.possibleExtendedMetalThicknessesRange;
        }
        return combineLatest([
          this.thicknessOptions,
          possibleThicknessesRange,
        ]).pipe(
          map(([options, range]) => {
            if (options && options.length && range) {
              return options.filter((x) => +x >= range.min && +x <= range.max);
            }
            return null;
          })
        );
      })
    );

    this.possibleWallpapers = this.getPossibleOptionsObservable(
      'GetPossibleWallpapers',
      [
        { name: 'code', selector: getSelectedMaterialCode, required: true },
        {
          name: 'thickness',
          selector: getSelectedMaterialThickness,
          required: true,
        },
      ]
    );

    this.possiblePerforationsSizes = this.getPossibleOptionsObservable(
      'getPossiblePerforationSizes',
      [
        { name: 'code', selector: getSelectedMaterialCode, required: true },
        {
          name: 'thickness',
          selector: getSelectedMaterialThickness,
          required: true,
        },
        { name: 'perf', selector: getPerforationType, required: true },
      ]
    );

    this.possiblePerforationFormats = this.getPossibleOptionsObservable(
      'GetPossiblePerforationFormats',
      [
        { name: 'code', selector: getSelectedMaterialCode, required: true },
        {
          name: 'thickness',
          selector: getSelectedMaterialThickness,
          required: true,
        },
        { name: 'perf', selector: getPerforationType, required: true },
        { name: 'size', selector: getPerforationSize, required: true },
      ]
    );

    this.possiblePerforationPositions = this.getPossibleOptionsObservable(
      'GetPossiblePositions',
      [
        { name: 'code', selector: getSelectedMaterialCode, required: true },
        {
          name: 'thickness',
          selector: getSelectedMaterialThickness,
          required: true,
        },
        { name: 'perf', selector: getPerforationType, required: true },
        { name: 'size', selector: getPerforationSize, required: true },
        { name: 'format', selector: getPerforationFormat, required: true },
      ]
    );

    this.thicknessOptions = combineLatest([
      this.store.pipe(select(getDataSetState)),
      this.store.pipe(select(getSelectedMaterialType)),
    ]).pipe(
      debounceTime(1),
      map(([dataset, materialType]) => {
        if (!dataset) {
          return [];
        }
        return getMaterialThickness(dataset, materialType);
      })
    );

    this.possibleCustomPatternForms = this.getPossibleOptionsObservable(
      'GetPossibleCustomPatternForms',
      [
        { name: 'code', selector: getSelectedMaterialCode, required: true },
        {
          name: 'thickness',
          selector: getSelectedMaterialThickness,
          required: true,
        },
      ]
    );

    this.possibleCustomPatternW = this.getPossibleOptionsObservable(
      'GetPossibleCustomPatternW',
      [
        { name: 'code', selector: getSelectedMaterialCode, required: true },
        {
          name: 'thickness',
          selector: getSelectedMaterialThickness,
          required: true,
        },
        { name: 'form', selector: getSelectedCustomStampFrom, required: true },
      ]
    );

    this.possibleCustomPatternL = this.getPossibleOptionsObservable(
      'GetPossibleCustomPatternL',
      [
        { name: 'code', selector: getSelectedMaterialCode, required: true },
        {
          name: 'thickness',
          selector: getSelectedMaterialThickness,
          required: true,
        },
        { name: 'form', selector: getSelectedCustomStampFrom, required: true },
        { name: 'w', selector: getSelectedCustomStampW, required: true },
      ]
    );
  }

  private getPossibleOptionsObservable(
    endPoint: string,
    params: Param[]
  ): Observable<string[] | any> {
    return combineLatest(
      params.map((x) => this.store.pipe(select(x.selector)))
    ).pipe(
      switchMap((values: string[]) => {
        let url: string = environment.api_url + `/api/dataset/${endPoint}`;
        const p = [...params];
        const v = [...values];
        p.push({name: 'appFilter', required: false, selector: null});
        v.push('d');
        for (let i = 0; i < p.length; i++) {
          const param = p[i];
          const value = v[i];
          if (param.required && !value) {
            return of(null);
          }
          if (i === 0) {
            url += '?';
          } else {
            url += '&';
          }
          url += `${param.name}=${value}`;
        }
        let resp = this.ceche[url];
        if (!resp) {
          this.ceche[url] = resp = this.http.get<string[]>(url).toPromise();
        }
        return from(resp);
      })
    );
  }
}
