import {ModelVisualizer} from "./model.visualizer";
import {DragArrowModel} from "../models/drag-arrow.model";
import {Webcad} from "../core";
import {DragArrow} from "../html-layer/drag-arrow";
import {ChangeType, detectChangesInMap} from "../utils";
import {CameraModel} from "../models/camera.model";
import * as BABYLON from "babylonjs";

export class DragArrowsVisualizer implements ModelVisualizer<Map<number, DragArrowModel>> {
    private visualizers: Map<number, DragArrow> = new Map<number, DragArrow>();
    private model: Map<number, DragArrowModel>;
    private webcad: Webcad;
    private rootNode: BABYLON.Node;
    private camera: CameraModel;

    constructor() {
    }

    init(rootNode: BABYLON.Node, model: Map<number, DragArrowModel>, webcad: Webcad): Promise<void> {
        model.forEach(async (v, k) => {
            const visualizer = new DragArrow();
            await visualizer.init(rootNode, v, webcad);
            this.visualizers.set(k, visualizer);
        });
        this.webcad = webcad;
        this.rootNode = rootNode;
        this.model = model;
        return;
    }

    updateVisualization(newModel: Map<number, DragArrowModel>): void {
        detectChangesInMap(this.model, newModel, (type: ChangeType, key: number) => {
                const visualizer = this.visualizers.get(key);
                switch (type) {
                    case ChangeType.Changed:
                        visualizer.updateVisualization(newModel.get(key));
                        break;
                    case ChangeType.Added:
                        if (visualizer) {
                            visualizer.updateVisualization(newModel.get(key));
                        } else {
                            const newVisualizer: DragArrow = new DragArrow();
                            this.visualizers.set(key, newVisualizer);
                            newVisualizer.init(this.rootNode, newModel.get(key), this.webcad);
                        }
                        break;
                    case ChangeType.Removed:
                        visualizer.updateVisualization(null);
                        break;
                }
            },
            this.camera === this.webcad.viewState.camera ? null : (key) => {
                const visualizer = this.visualizers.get(key);
                visualizer.updateVisualization(newModel.get(key));
            });
        this.model = newModel;
        this.camera = this.webcad.viewState.camera;
    }

    dispose(): void {
        this.visualizers.forEach((v) => {
            v.dispose();
        });
        this.visualizers.clear();
    }
}