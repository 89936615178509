import {ExpandedMetalVisualizerModel} from "../expanded-metal.vizualizer";
import {MevacoPage} from "../../model/mevaco-page.model";
import {
  getExpandedMetalGltfHash,
  ProductType,
  Step
} from "../../model/product-configuration/product-configuration.model";

export class ExpandedMetalViewModelProvider {
  private viewModel: ExpandedMetalVisualizerModel = {
    gltfId: null,
    type: "",
    shape: null,
    lwd: 100,
    swd: 100,
    offsetX: 0,
    offsetY: 0,
    feedrate: 1,
    divingDepth: 1,
    totalThickness: 1,
    materialThickness: 1,
    hexColorString: '#B0B0B0'
  };

  getViewModel(newModel: MevacoPage): ExpandedMetalVisualizerModel {
    const show = newModel.productConfiguration.productType === ProductType.ExtendedMetals && (newModel.productConfiguration.step === Step.pattern || newModel.productConfiguration.step === Step.design);
    if ( !show ) {
      return null;
    }

    const stremoe = newModel.productConfiguration.configuration?.stremoe;
    if (!stremoe || !stremoe.vorschubMin) {
      return null;
    }

    const strand = +newModel.productConfiguration.extendedMetal.feedrate;
    const minS = +stremoe.vorschubMin.replace(',', '.');
    const maxS = +stremoe.vorschubMax.replace(',', '.');
    let t = 1.0;
    if (minS !== maxS) {
      t = (strand - minS) / (maxS - minS);
    }

    const totalThickness = stremoe.gDickeMin * (1.0 - t) + stremoe.gDickeMin * t;

    const divingDepth = ((stremoe.sizeS - +newModel.productConfiguration.extendedMetal.feedrate - +newModel.productConfiguration.material.thickness) / 2 * 1.1);

    let offsetX = 0;
    let offsetY = 0;
    const aabb = newModel.drawing?.plate?.cutShapeWithHoles?.aabb;
    if (aabb) {
      switch (newModel.productConfiguration.extendedMetal.typeOfCut) {
        case 'F':
          offsetY = aabb.min.x * 1000 + (((aabb.max.x - aabb.min.y) * 1000) % stremoe.sizeS) / 2;
          break;
        case 'A':
        case 'B':
        case 'C':
        case 'H':
          offsetX = (aabb.min.x || 0) * 1000;
          break;
        case 'G':
          offsetX = (aabb.min.x || 0) * 1000 - stremoe.sizeS * 0.5;
          break;
      }

      switch (newModel.productConfiguration.extendedMetal.typeOfCut) {
        case 'B':
        case 'F':
          offsetY = aabb.min.y * 1000 + (((aabb.max.y - aabb.min.y) * 1000) % stremoe.sizeL) / 2;
          break;
        case 'C':
        case 'E':
        case 'H':
          offsetY = (aabb.min.y || 0) * 1000;
          break;
        case 'G':
          offsetY = (aabb.min.y || 0) * 1000  /*+ stremoe.sizeL / 2.0 */;
          break;
      }
    }

    const viewModel: ExpandedMetalVisualizerModel = show ? {
      gltfId: getExpandedMetalGltfHash(newModel.productConfiguration),
      type: stremoe.type,
      shape: newModel.drawing?.plate?.cutShapeWithHoles,
      lwd: stremoe.sizeL,
      swd: stremoe.sizeS,
      feedrate: +newModel.productConfiguration.extendedMetal.feedrate,
      offsetX: offsetX,
      offsetY: offsetY,
      totalThickness: totalThickness,
      divingDepth: divingDepth,
      materialThickness: +newModel.productConfiguration.material.thickness,
      hexColorString: newModel.drawing?.plate?.hexColorString || '#B0B0B0'
    } : null;

    //not loaded yet
    if ( !newModel.gltfs[viewModel.gltfId] ) {
      return null;
    }

    if ( this.viewModel.gltfId !== viewModel.gltfId || this.viewModel.shape !== viewModel.shape || this.viewModel.hexColorString !== viewModel.hexColorString) {
      this.viewModel = viewModel;
    }
    return this.viewModel;
  }
}
