import * as BABYLON from "babylonjs";
import {ModelVisualizer} from "./model.visualizer";
import {Webcad} from "../core/webcad";
import {CameraModel} from "../models/camera.model";
import {ObjectUnderPoint} from "../models/ObjectUnderPoint";
import {sqrDistanceVector2, Vector3} from "../math";

export class ViewModelVisualizer<ModelType> implements ModelVisualizer<ModelType> {

    protected parentNode: BABYLON.Node;
    protected model: ModelType;
    private webcad: Webcad;
    private cameraState: CameraModel;

    constructor(private visualizers: Map<ModelVisualizer<any>[], (model: ModelType) => Object>) {
    }

    updateVisualization(newModel: ModelType): void {
        if (this.model !== newModel || this.cameraState !== this.webcad.viewState.camera) {
            this.visualizers.forEach((callback, modelVisualizers) => {
                for (const propertyVisualizer of modelVisualizers) {
                    const feed: Object = callback(newModel);
                    propertyVisualizer.updateVisualization(newModel ? feed : null);
                }
            });
            this.model = newModel;
            this.cameraState = this.webcad.viewState.camera;
        }
    }


    initPropertyVisualizers(webcad: Webcad): Promise<void>[] {
        const result: Promise<void>[] = [];
        this.visualizers.forEach((callback, modelVisualizers) => {
            for (const propertyVisualizer of modelVisualizers) {
                const node: BABYLON.Node = new BABYLON.Node((<Object>propertyVisualizer).constructor.name, this.parentNode.getScene());
                node.parent = this.parentNode;
                const feed: Object = callback(this.model);
                const initPromise: Promise<void> = propertyVisualizer.init(node, this.model ? feed : null, webcad);
                const timeOut: Promise<void> = new Promise((resolve, reject) => {
                    setTimeout(() => {
                        reject(new Error("property init timeput: " + (<Object>propertyVisualizer).constructor.name));
                    }, 10000);
                });

                const combined: Promise<void> = new Promise<void>((resolve, reject) => {
                    Promise.race([initPromise, timeOut]).then(resolve, reject);
                });
                result.push(combined);
            }
        });
        return result;
    }

    init(parentNode: BABYLON.Node, model: ModelType, webcad: Webcad): Promise<void> {
        this.parentNode = parentNode;
        this.model = model;
        this.webcad = webcad;
        return new Promise<void>((resolve, reject) => {
            Promise.all(
                this.initPropertyVisualizers(webcad)
            ).then(() => {
                resolve();
            }, reject);
        });
    }

    dispose(): void {
        this.visualizers.forEach((callback, modelVisualizers) => {
            for (const propertyVisualizer of modelVisualizers) {
                propertyVisualizer.dispose();
            }
        });
    }
/*
    getObjectUnderPoint(point: Vector3, maxDist: number): ObjectUnderPoint {
        const objects: ObjectUnderPoint[] = [];
        this.visualizers.forEach((callback, modelVisualizers) => {
            for (const propertyVisualizer of modelVisualizers) {
                const obj = propertyVisualizer.getObjectUnderPoint(point, maxDist);
                if (obj) {
                    objects.push(obj);
                }
            }
        });
        let closestDist = Number.POSITIVE_INFINITY;
        let closest: ObjectUnderPoint = null;
        for (const o of objects) {
            const dist = sqrDistanceVector2(point, o.point);
            if (dist < closestDist) {
                closestDist = dist;
                closest = o;
            }
        }
        return closest;
    }
*/
}
