import {Action} from '@ngrx/store';
import {DrawingActions} from './drawing.actions';
import {ProductConfigurationActions} from './product-configuration.actions';
import {Step} from '../../model/product-configuration/product-configuration.model';
import {SaveActions} from './save.actions';
import {MevacoResponse} from '../../model/client-server.Params/mevaco-response.model';
import {ShapeWithHoles} from '../../model/shape-with-holes';
import {Segment} from 'webcad/models';
import {UserTemplateActions} from './user-template.actions';
import {Units} from '../../model/units';
import {AppMode} from '../../model/app-mode.enum';
import {Vector3} from "webcad/math";
import {UserPattern} from "../../model/user-pattern.model";
import { RightPanelActions } from './right-panel.actions';
import {PerforationCustomGeom} from "../../model/product-configuration/perforation-custom-geom";

export const INITIALIZE_PAGE = 'INITIALIZE PAGE';

export class InitializePage implements Action {
  readonly type: string = INITIALIZE_PAGE;

  constructor(public payload: any = null) {
  }

}

export const SET_APP_MODE = 'SET APP MODE';

export class SetAppMode implements Action {
  readonly type: string = SET_APP_MODE;

  constructor(public payload: AppMode) {

  }
}
export const CHANGE_START_POPUP = 'CHANGE_START_POPUP';
export class ChangeStartPopup implements Action {
  readonly type: string = CHANGE_START_POPUP;
  constructor(public payload: boolean) {
  }
}
export const SET_SELECTED_UNIT = 'SET_SELECTED_UNIT';
export class SetSelectedUnit implements Action {
  readonly type: string = SET_SELECTED_UNIT;
  constructor(public payload: string) {
  }
}
export const CHANGE_TEMPLATE_MODAL = 'CHANGE_TEMPLATE_MODAL';
export class ChangeTemplateModal implements Action {
  readonly type: string = CHANGE_TEMPLATE_MODAL;
  constructor(public payload: boolean) {
  }
}
export const SET_STEP = 'SET_STEP';

export class SetStep implements Action {
  readonly type: string = SET_STEP;

  constructor(public payload: Step) {
  }
}

export interface UploadDxfArgs {
  file: File;
  units: Units;
  useBlocks: boolean;
}

export interface UploadDxfResponse {
  id: number;
  shpes: Segment[][];
}

export const LOAD_DXF = 'LOAD DXF';

export class LoadDxf implements Action {
  readonly type: string = LOAD_DXF;

  constructor(public payload: UploadDxfArgs) {

  }
}

export const LOAD_DXF_SUCCESS = 'LOAD DXF SUCCESS';

export class LoadDxfSuccess implements Action {
  readonly type: string = LOAD_DXF_SUCCESS;

  constructor(public payload: MevacoResponse<UploadDxfResponse>) {

  }
}

export const GET_TRANSLATIONS = 'GET TRANSLATIONS';

export class GetTranslations implements Action {
  readonly type: string = GET_TRANSLATIONS;


  constructor(public payload: string) {

  }
}

export const GET_TRANSLATIONS_SUCCESS = 'GET TRANSLATIONS SUCCESS';

export class GetTranslationsSuccess implements Action {
  readonly type: string = GET_TRANSLATIONS_SUCCESS;

  constructor(public payload: { [module: string]: { [identifier: string]: string } }) {

  }
}

export const CALCULATE_SHAPE_FROM_POLYLINES = 'CALCULATE SHAPE FROM POLYLINES';

export class CalculateShapeFromPolylines implements Action {
  readonly type: string = CALCULATE_SHAPE_FROM_POLYLINES;

  constructor(public payload: Segment[][]) {

  }
}

export const CALCULATE_SHAPE_FROM_POLYLINES_SUCCESS = 'CALCULATE SHAPE FROM POLYLINES SUCESS';

export class CalculateShapeFromPolylinesSuccess implements Action {
  readonly type: string = CALCULATE_SHAPE_FROM_POLYLINES_SUCCESS;

  constructor(public payload: MevacoResponse<ShapeWithHoles>) {

  }
}

export const SET_BACKGROUND_COLOR = 'SET BACKGROUND COLOR';

export class SetBackgroundColor implements Action{
  readonly type: string = SET_BACKGROUND_COLOR;

  constructor(public payload: Vector3){

  }
}

export const SUBMIT_IMPORT = 'SUBMIT IMPORT';

export class SubmitImport implements Action {
  readonly type: string = SUBMIT_IMPORT;

  constructor(public paylod: any = null) {

  }
}

export const CANCEL_IMPORT = 'CANCEL IMPORT';

export class CancelImport implements Action {
  readonly type: string = CANCEL_IMPORT;

  constructor(public payload: any = null) {

  }
}

export const UPDATE_MOUNTINGS = 'UPDATE MOUNTINGS';

export class UpdateMountings implements Action {
  readonly type: string = UPDATE_MOUNTINGS;

  constructor(public payload: any = null) {

  }
}

export const SHOW_CUSTOM_PATTERN_EDITOR = 'UPDATE SHOW_CUSTOM_PATTERN_EDITOR';
export class ShowCustomPatternEditor implements Action {
  readonly type: string = SHOW_CUSTOM_PATTERN_EDITOR;
  constructor(public payload: boolean) {
  }
}

export const SET_PERFACTION_EDITOR_STATE = 'SET_PERFACTION_EDITOR_STATE';
export class SetPerfactionEditorState implements Action {
  readonly type: string = SET_PERFACTION_EDITOR_STATE;
  constructor(public payload: boolean) {
  }
}

export const ENABLE_PERFACTION = 'ENABLE_PERFACTION';
export class EnablePerfaction implements Action {
  readonly type: string = ENABLE_PERFACTION;
  constructor(public payload: boolean) {
  }
}

export const ENABLE_EFFECT = 'ENABLE_EFFECT';
export class EnableEffect implements Action {
  readonly type: string = ENABLE_EFFECT;
  constructor(public payload: boolean) {
  }
}

export const ENABLE_FADING = 'ENABLE_FADING';
export class EnableFading implements Action {
  readonly type: string = ENABLE_FADING;
  constructor(public payload: boolean) {
  }
}

export const RESET_CUSTOM_PATTERN = 'RESET_CUSTOM_PATTERN';
export class ResetShowCustomPattern implements Action {
  readonly type: string = RESET_CUSTOM_PATTERN;
  constructor() {
  }
}

export const RESET_CUSTOM_SUCCEEDED = 'RESET_CUSTOM_SUCCEEDED';
export class ResetShowCustomPatternSUCCEEDED implements Action {
  readonly type: string = RESET_CUSTOM_SUCCEEDED;
  constructor(public payload: PerforationCustomGeom) {
  }
}

export const SET_CUSTOM_PATTERN = 'SET_CUSTOM_PATTERN';
export class SetCustomPattern implements Action {
  readonly type: string = SET_CUSTOM_PATTERN;
  constructor(public payload: UserPattern) {
  }
}

export const BLOCK_USER = 'BLOCK_USER';
export class BlockUser implements Action {
  readonly type: string = BLOCK_USER;
  constructor(public payload: boolean) {
  }
}

export const LOAD_GLTF = 'LOAD_GLTF';
export class LoadGltf implements Action {
  readonly type: string = LOAD_GLTF;
  constructor(public payload: string, public swd: number, public totalThickness: number, public feedrate: number, public materialThickness) {
  }
}

export const GLTF_LADED = 'GLTF_LADED';
export class GltfLoaded implements Action {
  readonly type: string = GLTF_LADED;
  constructor(public payload: string) {
  }
}

export const SET_EXPANDED_METAL_CUT_SHAPE = 'SET_EXPANDED_METAL_CUT_SHAPE';
export class SetExpandedMetalCutShape implements Action {
  readonly type: string = SET_EXPANDED_METAL_CUT_SHAPE;
  constructor(public payload: ShapeWithHoles) {
  }
}

export type MevacoPageActions =
  InitializePage |
  DrawingActions |
  ProductConfigurationActions |
  SetStep |
  ChangeStartPopup |
  SaveActions
  | CalculateShapeFromPolylines
  | CalculateShapeFromPolylinesSuccess
  | LoadDxf
  | LoadDxfSuccess
  | UserTemplateActions
  | RightPanelActions
  ;
