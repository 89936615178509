import {ModelVisualizer} from '../model.visualizer';
import {Input3DModel} from '../../models/input-html/input3d.model';
import {Webcad} from '../../core';
import {Input2DModel} from '../../models/input-html/input2d.model';
import {addVectors3, crossVector2, Vector2, Vector3} from '../../math';
import {Vector4} from '../../math/vector4';
import {InputHtml} from '../../html-layer/input-html';
import {ObjectUnderPoint} from '../../models/ObjectUnderPoint';
import {toScreen} from '../../html-layer/utils';
import * as BABYLON from 'babylonjs';

export class Input3DVisualizer<T> implements ModelVisualizer<Input3DModel<T>> {

    private input2DVisualizer: InputHtml<T>;
    private model: Input3DModel<T>;
    private webcad: Webcad;

    constructor() {
        this.input2DVisualizer = new InputHtml();
    }


    init(rootNode: BABYLON.Node, model: Input3DModel<any>, webcad: Webcad): Promise<void> {
        this.model = model;
        this.webcad = webcad;
        return this.input2DVisualizer.init(rootNode, null, webcad);
    }

    setFocus(focus: boolean): void {
        this.input2DVisualizer.setFocus(focus);
    }

    private calculate2dModel(model: Input3DModel<any>): Input2DModel<any> {
        if (!model) {
            return null;
        }

        // var viewProjectionMatrix = this.rotateCamera.getViewMatrix()
        //    .multiply(this.rotateCamera.getProjectionMatrix());

        const pos: Vector4 = toScreen(model.position, this.webcad.viewState.camera);
        const pos2: Vector4 = toScreen(addVectors3(model.position, model.dir), this.webcad.viewState.camera);

        // MDV1-240 text direction switch point should not be at the 90deg since it's the post popular orientanion and
        // there it's quite random and flickers
        const upVector: Vector2 = {
            x: -0.05,
            y: 1
        };
        let dirX = pos2.x - pos.x;
        let dirY = pos2.y - pos.y;
        const dir: Vector2 = {
            x: dirX,
            y: dirY
        }
        if (crossVector2(dir, upVector) < 0) {
            dirX *= -1;
            dirY *= -1;
        }
        let angle = 0;
        if (dirX * dirX + dirY * dirY > 0.0001) {// len > 0.01
            // var engine = this.rotateCamera.getScene().getEngine();
            // const viewPortRatio = engine.getRenderWidth()/engine.getRenderHeight();
            const viewPortRatio = this.webcad.viewState.camera.projection.n22 / this.webcad.viewState.camera.projection.n11;
            angle = Math.atan2(-dirY / viewPortRatio, dirX);
        }
        return {
            editable: model.editable,
            placement: {
                position: {x: pos.x, y: pos.y},
                angle: angle,
            },
            exchange: model.exchange
        };
    }

    updateVisualization(newModel: Input3DModel<any>): void {
        this.input2DVisualizer.updateVisualization(this.calculate2dModel(newModel));
        this.model = newModel;
    }

    dispose(): void {
        if (this.input2DVisualizer) {
            this.input2DVisualizer.dispose();
        }
    }

    getObjectUnderPoint(point: Vector3, maxDist: number): ObjectUnderPoint {
        return this.input2DVisualizer.getObjectUnderPoint(point, maxDist);
    }

}
