import * as BABYLON from 'babylonjs';
import Observable = BABYLON.Observable;
import Nullable = BABYLON.Nullable;


export class RotateCamera extends BABYLON.ArcRotateCamera {

    public rotationOrigin: BABYLON.Vector3;
    private _radius: number = 0;
    private _alpha: number = 0;
    private _beta: number = 0;
    public _position: BABYLON.Vector3 = new BABYLON.Vector3(0, 0, 0);
    private _inertialRadiusOffset: number;
    private _inertialAlphaOffset: number;
    private _inertialBetaOffset: number;
    private _inertialPanningX: number;
    private _inertialPanningY: number;
    private _isDirty: boolean;
    private _dispatched: boolean;
    public onCameraChangedObservable = new Observable<Nullable<RotateCamera>>();
    private _targetPosition: BABYLON.Vector3;
    private __newPosition: BABYLON.Vector3;

    constructor(name: string, alpha: number, beta: number, radius: number, target: BABYLON.Vector3, scene: BABYLON.Scene, setActiveOnSceneIfNoneActive?: boolean) {
        super(name, alpha, beta, radius, target, scene, setActiveOnSceneIfNoneActive);
        (<BABYLON.ArcRotateCameraPointersInput>this.inputs.attached.pointers).buttons = [2];
        this._isDirty = true;
        this._dispatched = false;
        this._inertialAlphaOffset = 0;
        this._inertialBetaOffset = 0;
        this._inertialPanningX = 0;
        this._inertialPanningY = 0;
        this._inertialRadiusOffset = 0;
        this._radius = radius;
        this._alpha = alpha;
        this._beta = beta;
        this.__newPosition = this._position.clone();
        this._targetPosition = target ? new BABYLON.Vector3(target.x, target.y, target.z) : null;
    }

    public set _newPosition(value: BABYLON.Vector3) {
        this.__newPosition = value;
    }

    public get _newPosition(): BABYLON.Vector3 {
        return this.__newPosition;
    }

    public set radius(value: number) {
        this._radius = value;
        this.isDirty = true;
    }

    public get radius(): number {
        return this._radius;
    }

    public set alpha(value: number) {
        this._alpha = value;
        this.isDirty = true;
    }

    public get alpha(): number {
        return this._alpha;
    }

    public set beta(value: number) {
        this._beta = value;
        this.isDirty = true;
    }

    public get beta(): number {
        return this._beta;
    }

    // set target(value: BABYLON.Vector3) {
    //     this.setTarget(value);
    // }

    get inertialRadiusOffset(): number {
        return this._inertialRadiusOffset;
    }

    set inertialRadiusOffset(value: number) {
        this._inertialRadiusOffset = value;
        this.isDirty = true;
    }

    get inertialAlphaOffset(): number {
        return this._inertialAlphaOffset;
    }

    set inertialAlphaOffset(value: number) {
        this._inertialAlphaOffset = value;
        this.isDirty = true;
    }

    get inertialBetaOffset(): number {
        return this._inertialBetaOffset;
    }

    set inertialBetaOffset(value: number) {
        this._inertialBetaOffset = value;
        this.isDirty = true;
    }

    get isDirty(): boolean {
        return this._isDirty;
    }

    set isDirty(value: boolean) {
        this._isDirty = value;
        this.cameraChanged();
    }

    private cameraChanged(): void {
        if (this.onCameraChangedObservable && (this.isDirty || (this.target && this._targetPosition && BABYLON.Vector3.DistanceSquared(this.target, this._targetPosition)) > 0.0001)) {
            if (this.target && this._targetPosition) {
                this._targetPosition = new BABYLON.Vector3(this.target.x, this.target.y, this.target.z);
            }
            this.onCameraChangedObservable.notifyObservers(this);
            this.isDirty = false;
        }
    }

    public _checkInputs(): void {

        if (this.rotationOrigin) {
            if (this.inertialAlphaOffset !== 0 || this.inertialBetaOffset !== 0 || this.inertialRadiusOffset !== 0) {
                let inertialAlphaOffset = this.inertialAlphaOffset;
                if (this.beta <= 0) inertialAlphaOffset *= -1;
                if (this.getScene().useRightHandedSystem) inertialAlphaOffset *= -1;
                if (this.parent && this.parent._getWorldMatrixDeterminant() < 0) inertialAlphaOffset *= -1;
                let newTarget = this._target;
                newTarget = newTarget.subtract(this.rotationOrigin);

                const alphaRotationMatrix = BABYLON.Matrix.RotationY(-inertialAlphaOffset);
                newTarget = BABYLON.Vector3.TransformCoordinates(newTarget, alphaRotationMatrix);

                newTarget = newTarget.add(this.rotationOrigin);

                this._target = newTarget;
                this.isDirty = true;
            }
        }
        super._checkInputs();
    }

    public _updatePosition(): void {
        const currentZ = this.position.z;
        super._updatePosition();
        const scene = this.getScene();
        const untranstlated = scene.unTranslatedPointer;
        const wantedZ = this.position.z;
        const ray = scene.createPickingRay(untranstlated.x, untranstlated.y, null, this, false);
        // const positionV = linePlaneIntersection(ray.direction, ray.origin, {x: 0, y: 0, z: -1}, {x: 0, y: 0, z: 0});
        this.setPosition(this.position.add(ray.direction.scale(currentZ - wantedZ)));
        this.isDirty = true;
    }

    public rebuildAnglesAndRadius(): void {
        super.rebuildAnglesAndRadius();
        this.isDirty = true;
    }

    public setPosition(position: BABYLON.Vector3): void {
        super.setPosition(position);
        this._newPosition = position.clone();
        this._position = this.position;
        this._globalPosition = this.position;
        this.isDirty = true;
    }

    setTarget(target: BABYLON.AbstractMesh | BABYLON.Vector3): void {
        if (target) {
            super.setTarget(target);
            this.isDirty = true;
        }
    }
}
