import * as BABYLON from 'babylonjs'
import {Vector4} from "../math/vector4";
import {Matrix4} from "../math/matrix4";
import {CameraModel} from "../models/camera.model";
import {Vector3} from "../math";

export function babylon4ToVector4(v: BABYLON.Vector4): Vector4 {
    return {
        x: v.x,
        y: v.y,
        z: v.z,
        w: v.w,
    }
}

export function babylon3ToVector4(v: BABYLON.Vector3, w = 1): Vector4 {
    return {
        x: v.x,
        y: v.y,
        z: v.z,
        w: w,
    }
}


export function babylon3ToVector3(v: BABYLON.Vector3, w = 1): Vector3 {
    return {
        x: v.x,
        y: v.y,
        z: v.z,
    }
}

export function vector3ToBabylon3(v: Vector3, w = 1): BABYLON.Vector3 {
    return new BABYLON.Vector3(v.x, v.y, v.z);
}

export function babylonToMatrix4(m: BABYLON.Matrix): Matrix4 {
    return {
        n11: m.m[0], n12: m.m[4], n13: m.m[8], n14: m.m[12],
        n21: m.m[1], n22: m.m[5], n23: m.m[9], n24: m.m[13],
        n31: m.m[2], n32: m.m[6], n33: m.m[10], n34: m.m[14],
        n41: m.m[3], n42: m.m[7], n43: m.m[11], n44: m.m[15],
    }
}

export function babylonToCamera(c: BABYLON.Camera): CameraModel {
    if (!!c) {
        return {
            projection: babylonToMatrix4(c.getProjectionMatrix()),
            view: babylonToMatrix4(c.getViewMatrix())
        }
    } else {
        return {
            projection: babylonToMatrix4(c.getProjectionMatrix()),
            view: babylonToMatrix4(c.getViewMatrix())
        }

    }
}