import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslationProvider } from '../../../../providers/translation.provider';
import {
  ChangeTemplateModal,
  getExpandedMetalTemplate,
  getSelectedExpandedMetalSizeDecode,
  getSelectedExpandedMetalTypeOfCut, ProductConfigurationAddElement
} from '../../../../store';
import { ExpandedMetalParams } from '../../../../model/basic-templates.model';
import { SetExpandedMetalHeight, SetExpandedMetalWidth } from '../../../../store/actions/element-creator.actions';
import { TemplateUtils } from '../../templatesUtils';
import {Subscription} from 'rxjs';
import {createRectangle, ShapeWithHoles} from '../../../../model/shape-with-holes';
import * as hash from 'object-hash';
import {Element, ElementType} from '../../../../model/product-configuration/element.model';
import {TemplateName} from '../../template-name';
import {Guid} from 'guid-typescript';
import {aabbOfPolyline} from 'webcad';

@Component({
  selector: 'mevaco-rectangle-expanded-metal',
  templateUrl: './rectangle-expanded-metal.component.html',
  styleUrls: ['./rectangle-expanded-metal.component.css']
})
export class RectangleExpandedMetalComponent implements OnInit, OnDestroy {

  @Output() dismissModal = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() isModalOpen = new EventEmitter();

  expandedMetalParams: ExpandedMetalParams = null;
  expandedMetalSize: {
    a: number,
    b: number,
  } = null;
  expandedMetalTypeOfCut: string;
  private subs: Subscription[];
  constructor(
    private readonly store: Store,
    private readonly translationProvider: TranslationProvider,
    public templateUtils: TemplateUtils ) {}

  ngOnInit(): void {
    this.registerParams();
  }
  ngOnDestroy() {
    for (let i = 0; i < this.subs.length; i++) {
      const sub = this.subs[i];
      sub.unsubscribe();
    }
  }

  registerParams() {
    this.subs = [
      this.store.pipe(select(getExpandedMetalTemplate)).subscribe((v) => this.expandedMetalParams = { ...v }),
      this.store.pipe(select(getSelectedExpandedMetalSizeDecode)).subscribe((v) => this.expandedMetalSize = { ...v }),
      this.store.pipe(select(getSelectedExpandedMetalTypeOfCut)).subscribe((v) => this.expandedMetalTypeOfCut = v)
    ];
  }

  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }



  setWidth(event: Event & {target: HTMLInputElement}) {
    const { value } = event.target;
    this.store.dispatch(new SetExpandedMetalWidth(+value));
  }

  setHeight(event: Event & {target: HTMLInputElement}) {
    const { value } = event.target;
    this.store.dispatch(new SetExpandedMetalHeight(+value));
  }

  createPosition() {

    const a = this.expandedMetalParams.height / 1000;
    const b = this.expandedMetalParams.width / 1000;
    const contures = createRectangle({ x: 0, y: 0 }, { x: b, y: a });
    const aabb = aabbOfPolyline(contures);
    const area = b * a;
    const shape: ShapeWithHoles = {
      conture: contures,
      holes: [],
      aabb: aabb,
      area: area,
    };


    const aIst = +this.calcHeight() / 1000;
    const bIst = +this.calcWidth() / 1000;
    const ad = (a - aIst) / 2;
    const bd = (b - bIst) / 2;


    const conturesIst = createRectangle({ x: bd, y: ad }, { x: b - bd, y: a - ad });
    const aabbIst = aabbOfPolyline(conturesIst);
    const cutShape: ShapeWithHoles = {
      conture: conturesIst,
      holes: [],
      aabb: aabbIst,
      area: aIst * bIst,
    };

    const element: Element = {
      type: ElementType.individual,
      templateName: TemplateName.basicRectangle,
      boundary: null,
      position: -1, // will be set inb reducer
      quantity: 1,
      a: a,
      b: b,
      aIst: +this.calcHeight() / 1000,
      bIst: +this.calcWidth() / 1000,
      e1: '',
      e2: '',
      f1: '',
      f2: '',
      e1ist: '',
      e2ist: '',
      f1ist: '',
      f2ist: '',
      openMeshE: '',
      openMeshF: '',
      toleranceWidth: 0,
      toleranceLength: 0,
      label: '',
      note: '',
      unperforated: false,
      posibleCoil: 'No',
      posiblePlate: 'No',
      shapes: null,
      nodes: null,
      verticesIndexes: null,
      visualizationShape: null,
      previewImageId: Guid.create().toString(),
      shape: shape,
      perforationAreas: [],
      cutShape: cutShape,
      helpLines: [],
      measurements: [],
      angleMeasurements: [],
      mountings: [],
      perforationAutoCenter: true,
      area: shape.area,
      minRadius: null,
      minParallelEdgesDistance: null,
      minMarginDistance: null,
      isPerforationSimpleRect: null,
      isShapeSimpleRect: null,
      minMountingHoleEdgeDistance: null,
      minMountingHolesDistance: null,
      minOutsideAngle: null,
      minDistanceBetweenMountingAndPerforatedArea: null,
      minDistanceBetweenEdges: null,
      numberOfArcs: null,
      // perforation: null,
      possibleAllAcross: true,
      minDistanceBetweenPerforationAreas: null,
      lfbIst: undefined,
      lflIst: undefined,
      breakLines: [],
      bendingLinesDistances: [],
      // possiblePerforationZones: []
    };
    this.store.dispatch(new ProductConfigurationAddElement(element));
    this.store.dispatch(new ChangeTemplateModal(false));
    this.closeModal.emit('create');
  }


  dismiss(str: string) {
    this.store.dispatch(new ChangeTemplateModal(false));
    this.dismissModal.emit(str);
  }

  isModalOpenMethod() {
    this.isModalOpen.emit(false);
  }

  calcWidth(): string {
    if (this.expandedMetalSize && this.expandedMetalParams && this.expandedMetalTypeOfCut) {
      const b = this.expandedMetalParams.width;
      const fb = this.expandedMetalSize.b;
      switch (this.expandedMetalTypeOfCut) {
        case 'A':
        case 'B':
        case 'C':
          return (Math.floor(b / (fb / 2)) * (fb / 2)).toFixed(0);
        case 'G':
        case 'H':
          return (Math.floor(b / (fb)) * (fb)).toFixed(0);
      }
    }
    return this.expandedMetalParams.width.toFixed(1);
  }
  calcHeight(): string {
    if (this.expandedMetalSize && this.expandedMetalParams && this.expandedMetalTypeOfCut) {
      const a = this.expandedMetalParams.height;
      const fa = this.expandedMetalSize.a;
      switch (this.expandedMetalTypeOfCut) {
        case 'C':
        case 'E':
          return (Math.floor(a / (fa / 2)) * (fa / 2)).toFixed(0);
        case 'G':
        case 'H':
          return (Math.floor(a / (fa)) * (fa)).toFixed(0);
      }
    }
    return this.expandedMetalParams.height.toFixed(1);
    return this.expandedMetalParams.height.toFixed(1);
  }
}
