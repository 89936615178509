import * as BABYLON from "babylonjs";

export class InstancedMesh extends BABYLON.Mesh {
    constructor(name: string,
                private count: number,
                scene?: BABYLON.Nullable<BABYLON.Scene>,
                parent?: BABYLON.Nullable<BABYLON.Node>,
                source?: BABYLON.Nullable<BABYLON.Mesh>,
                doNotCloneChildren?: boolean,
                clonePhysicsImpostor?: boolean) {
        super(name, scene, parent, source, doNotCloneChildren, clonePhysicsImpostor);
    }

    _checkOcclusionQuery(): boolean {
        this.isOccluded = false;
        return this.isOccluded;
    }

    _getInstancesRenderList(subMeshId: number): BABYLON._InstancesBatch {
        const renderSelf: boolean[] = [];
        renderSelf[subMeshId] = true;
        const visibleInstances: BABYLON.InstancedMesh[][] = [];
        visibleInstances[subMeshId] = [];
        return {
            mustReturn: false,
            renderSelf: renderSelf,
            visibleInstances: visibleInstances
        };

    }

    _renderWithInstances(subMesh: BABYLON.SubMesh,
                         fillMode: number,
                         batch: BABYLON._InstancesBatch,
                         effect: BABYLON.Effect,
                         engine: BABYLON.Engine): BABYLON.Mesh {


        this._bind(subMesh, effect, fillMode);
        this._draw(subMesh, fillMode, this.count);

        engine.unbindInstanceAttributes();
        return this;
    }

    // public render(subMesh: BABYLON.SubMesh, enableAlphaMode: boolean): BABYLON.Mesh {
    //     return super.render(subMesh, enableAlphaMode);
    // }
}
