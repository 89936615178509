import {AngleMeasurementModel, getMeasurementEndPoint, MeasurementModel} from '../models';
import {MeasurementsManagerVisualizer} from './measurements-manager.visualizer';
import {View3D, Webcad} from '../core';
import {lengthVector3, multiplyVector3byScalar, subVectors3, Vector3} from '../math';
import {Observable} from 'rxjs';
import {changeMapValue} from '../utils';
import {MeasurementsModel} from './measurements.view.model';
import {MeasurementModelManager} from './measurement-model.manager';
import {AngleModelManager} from './angle-measurement-model.manager';
import {ViewState} from '../models/view-state.model';
import {ParseDecimalNumber} from '../utils/parse-number';
import * as BABYLON from 'babylonjs';

export class MeasurementsManager {
    models: MeasurementsModel;
    managers: Map<number, MeasurementModelManager>;
    angleManagers: Map<number, AngleModelManager>;
    visualizer: MeasurementsManagerVisualizer;
    rootNode: BABYLON.Node;
    private shouldUpdate: boolean = false;
    private currentFocused: number = null;
    private lastViewState: ViewState = null;

    constructor(private scene: BABYLON.Scene, private webcad: Webcad) {
    }

    init(): Promise<void> {
        this.models = {
            collection: new Map<number, MeasurementModel>(),
            angleModels: new Map<number, AngleMeasurementModel>()
        };
        this.managers = new Map<number, MeasurementModelManager>();
        this.angleManagers = new Map<number, AngleModelManager>();
        this.rootNode = new BABYLON.Node('MeasurmentsVisualization', this.scene);
        this.visualizer = new MeasurementsManagerVisualizer();

        this.scene.onBeforeRenderObservable.add((ed, es) => {
            if (this.shouldUpdate || this.webcad.viewState !== this.lastViewState || this.scene._activeAnimatables.length > 0) {
                this.lastViewState = this.webcad.viewState;
                this.visualizer.updateVisualization(this.models);
                this.shouldUpdate = false;
                (<View3D<any>>this.webcad).shouldUpdate = true;
            }
        });
        return this.visualizer.init(this.rootNode, this.models, this.webcad);
    }

    getMeasurementModel(): MeasurementModelManager {
        const measurementModel: MeasurementModel = {
            editable: true,
            start: {x: 0, y: 0, z: 0},
            measurementDirection: {x: 1, y: 0, z: 0},
            direction: {x: 0, y: 0, z: 1},
            maxValue: 1,
            focusable: true,
            visible: false,
            distance: 0,
            exchange: {
                value: 1,
                onInputLive: null,//to be set by tool
                onInputConfirmed: null,//to be set by tool
                inputValidation: (val) => {
                    const parsed = ParseDecimalNumber(val);
                    if (val !== '' && parsed !== undefined) {
                        if (parsed >= 0 && parsed < 10000) {
                            return true;
                        }
                    }
                    return false;
                },

                fromModel: (value: number) => (Math.round(value * 10000) / 10).toString(),
                toModel: (value: string) => (ParseDecimalNumber(value) / 1000)
            },
            mask:0x00000001
        };

        return this.addMeasurement(measurementModel);
    }

    addMeasurement(measurementModel: MeasurementModel): MeasurementModelManager {
        let i: number = 0;
        let done: boolean = false;
        while (!done) {
            if (!this.models.collection.get(i)) {
                this.models = {
                    ...this.models,
                    collection: changeMapValue(this.models.collection, measurementModel, i)
                };
                const manager: MeasurementModelManager = new MeasurementModelManager(i, this);
                this.managers.set(i, manager);
                this.shouldUpdate = true;
                return manager;
            } else {
                i++;
            }
        }
    }

    getAngleMeasurementModel(): AngleModelManager {
        const measurementModel: AngleMeasurementModel = {
            editable: true,
            origin: {x: 0, y: 0, z: 0},
            axis: {x: 0, y: 0, z: 1},
            startDir: {x: 1, y: 0, z: 0},
            radius: 0,//auto
            visible: false,
            exchange: {
                value: 0,
                onInputLive: null,//to be set by tool
                onInputConfirmed: null,//to be set by tool
                inputValidation: (val) => {
                    const parsed = ParseDecimalNumber(val);
                    if (val !== '' && parsed !== undefined) {
                        if (parsed > -10000 && parsed < 10000) {
                            return true;
                        }
                    }
                    return false;
                },
                fromModel: (value: number) => (value * 180 / Math.PI).toFixed(2),
                toModel: (value: string) => (ParseDecimalNumber(value) / 180 * Math.PI)
            },
        };

        let i: number = 0;
        let done: boolean = false;
        while (!done) {
            if (!this.models.angleModels.get(i)) {
                this.models = {
                    ...this.models,
                    angleModels: changeMapValue(this.models.angleModels, measurementModel, i)
                };
                const manager: AngleModelManager = new AngleModelManager(i, this);
                this.angleManagers.set(i, manager);
                this.shouldUpdate = true;
                return manager;
            } else {
                i++;
            }
        }
    }

    setStartForModel(id: number, position: Vector3): void {
        const oldModel = this.models.collection.get(id) as MeasurementModel;
        const oldEnd = getMeasurementEndPoint(oldModel);
        const v = subVectors3(oldEnd, position);
        const newValue = lengthVector3(v);
        const newDir = multiplyVector3byScalar(v, 1 / newValue);

        const newModel: MeasurementModel = {
            ...oldModel,
            start: position,
            measurementDirection: newDir,
            exchange: {
                ...oldModel.exchange,
                value: newValue
            }
        };
        this.models = {
            ...this.models,
            collection: changeMapValue(this.models.collection, newModel, id)
        };
        this.shouldUpdate = true;
    }

    setEndForModel(id: number, position: Vector3): void {
        const oldModel = this.models.collection.get(id) as MeasurementModel;
        const v = subVectors3(position, oldModel.start);
        const newValue = lengthVector3(v);
        const newDir = multiplyVector3byScalar(v, 1 / newValue);

        if (isNaN(position.x)) debugger;
        const newModel: MeasurementModel = {
            ...this.models.collection.get(id) as MeasurementModel,
            measurementDirection: newDir,
            exchange: {
                ...oldModel.exchange,
                value: newValue
            }
        };
        this.models = {
            ...this.models,
            collection: changeMapValue(this.models.collection, newModel, id)
        };
        this.shouldUpdate = true;
    }

    setDirectionForModel(id: number, direction: Vector3): void {
        const newModel: MeasurementModel = {
            ...this.models.collection.get(id),
            direction: direction
        };
        this.models = {
            ...this.models,
            collection: changeMapValue(this.models.collection, newModel, id)
        };
        this.validateModel();
        this.shouldUpdate = true;
    }

    setMaxValueForModel(id: number, maxValue: number): void {
        const newModel: MeasurementModel = {
            ...this.models.collection.get(id),
            maxValue: maxValue
        };
        this.models = {
            ...this.models,
            collection: changeMapValue(this.models.collection, newModel, id)
        };
        this.shouldUpdate = true;
    }

    setVisible(id: number, visible: boolean): void {
        const newModel: MeasurementModel = {
            ...this.models.collection.get(id),
            visible: visible
        };
        this.models = {
            ...this.models,
            collection: changeMapValue(this.models.collection, newModel, id)
        };
        this.shouldUpdate = true;
    }

    setEditable(id: number, editable: boolean): void {
        const newModel: MeasurementModel = {
            ...this.models.collection.get(id),
            editable: editable
        };
        this.models = {
            ...this.models,
            collection: changeMapValue(this.models.collection, newModel, id)
        };
        this.shouldUpdate = true;
    }

    disposeModel(id: number): void {
        this.managers.delete(id);
        const newCollection = new Map<number, MeasurementModel>([...Array.from(this.models.collection.entries())]);
        newCollection.delete(id);
        this.models = {
            ...this.models,
            collection: newCollection,
        };
        this.shouldUpdate = true;
    }

    disposeAngleModel(id: number): void {
        this.models = {
            ...this.models,
            angleModels: new Map<number, AngleMeasurementModel>([...Array.from(this.models.angleModels.entries())]),
        };
        this.models.angleModels.delete(id);
        this.angleManagers.delete(id);
        this.shouldUpdate = true;
    }

    getVisualizerInputSubscription(id: number): Observable<number> {
        return this.visualizer.getVisualizerInputSubscription(id);
    }

    setModelWithId(id: number, model: MeasurementModel): void {
        // const newModel: MeasurementModel = {...this.models.collection.get(id)};
        // const keys: string[] = Object.keys(model);
        // for (const k of keys) {
        //     newModel[k] = model[k];
        // }
        this.models = {
            ...this.models,
            collection: changeMapValue(this.models.collection, model, id)
        };
        this.validateModel();
        this.shouldUpdate = true;
    }

    setAngleModelWithId(id: number, model: AngleMeasurementModel): void {
        this.models = {
            ...this.models,
            angleModels: changeMapValue(this.models.angleModels, model, id)
        };
        this.shouldUpdate = true;
    }

    focusVisualizer(id: number | null): void {
        // if (this.currentFocused === id) {
        //     return;
        // }
        this.visualizer.setVisualizerFocus(id);
        this.currentFocused = id;
    }

    getStartOfModel(id: number): Vector3 {
        return this.models.collection.get(id).start;
    }

    getEndOfModel(id: number): Vector3 {
        return getMeasurementEndPoint(this.models.collection.get(id));
    }

    getModelWithId(id: number): MeasurementModel {
        return this.models.collection.get(id);
    }

    getAngleModelWithId(id: number): AngleMeasurementModel {
        return this.models.angleModels.get(id);
    }

    dispose(): void {
        const managers: MeasurementModelManager[] = Array.from(this.managers.values());
        this.managers.clear();
        this.models = {
            ...this.models,
            collection: new Map<number, MeasurementModel>()
        };
        this.currentFocused = null;
    }

    validateModel() {
        this.models.collection.forEach((measurementModel) => {
            if (isNaN(measurementModel.direction.x)) {
                debugger;
            }
        });
    }

}
