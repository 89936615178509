
   <div class="dialog-modal-header">
    <div class="dialog-modal-header-content">
      <div class="dialog-modal-title-container">
        <h4 class="dialog-modal-title-primary" id="modal-basic-title">
          {{ translate("Template Expanded Metal Title") }}
        </h4>
        <span class="dialog-modal-title-secondary"
          >{{
            translate("Template Expanded Metal Subtitle")
          }}</span
        >
      </div>
    </div>
    <button
      type="button"
      class="close btn-close"
      aria-label="Close"
      (click)="dismiss('Cross click'); isModalOpenMethod()"
      [tabIndex]="-1"
    ></button>
  </div>
<div class="dialog-modal-body">
    <div class="dialog-model-bg" >
      <input class="width" [value]="expandedMetalParams.width" (input)="setWidth($event)"  />
      <input class="calculated-input cal-width" [value]="calcWidth()"/>
      <input class="height" [value]="expandedMetalParams.height" (input)="setHeight($event)"  />
      <input class="calculated-input cal-height" [value]="calcHeight()"/>
    </div>
</div>



<div class="dialog-modal-footer">
  <span (click)="this.templateUtils.setDefault()" class="dialog-modal-link">
    {{
      this.templateUtils.translate("Reset dimensions to default.", "designer")
    }}
  </span>
  <button
    #closeButton
    type="button"
    class="btn btn-primary btn-create"
    [disabled]="expandedMetalParams.width <= 0 || expandedMetalParams.height <= 0"
    [tabIndex]="1"
    (click)="createPosition()"
  >
    {{ this.templateUtils.translate("Create") }}
  </button>
</div>
